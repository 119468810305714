<ion-content class="app update-app">
  <ion-header class="ion-no-border">
    <div
      class="close"
      *ngIf="update.type == 'FLEXIBLE'"
      (click)="close()"
      inlineSVG="assets/icons/close.svg"
    ></div>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="container">
      <h2 class="ion-no-margin">{{ 'update.available' | translate }}</h2>
      <p>{{update.message}}</p>
      <button (click)="goToUpdate()" class="button button--primary">
        {{ 'update.do' | translate }}
      </button>
    </div>
  </ion-content>
</ion-content>

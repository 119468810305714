<ion-content class="app project">
  <app-loader [full]="true" [loading]="loading"></app-loader>
  <ng-container *ngIf="!loading">
    <div class="project__header" [class.dark]="theme == 'dark'">
      <div class="project__header__cover" *ngIf="header == 'show'">
        <div
          [style.background-image]="project.coverImageThumbnails ? 'url(' + project.coverImageThumbnails.medium + ')' : 'none'"
        ></div>
        <div></div>
      </div>

      <div class="project__header__info">
        <div class="project__image">
          <img
            *ngIf="project.logoThumbnails"
            appImgFallback
            [src]="project.logoThumbnails.small"
            width="48"
            height="48"
            alt="{{ project.name }}"
          />
          <img
            *ngIf="!project.logoThumbnails"
            appImgFallback
            [src]="project.customer.logoThumbnails?.small"
            width="48"
            height="48"
            alt="{{ project.name }}"
          />
        </div>
        <div class="project__info">
          <span class="location" *ngIf="project.hasParentProject">
            <span
              >{{ 'project.home.has-parent' | translate:{project:
              project.parentProject.name} }}</span
            >
          </span>
          <h1>{{ project.name }}</h1>
          <span>{{ project.participants }}</span>
          <ul>
            <li>
              {{ 'project.home.started' | translate }} {{ project.startDate |
              date:'longDate' }}
            </li>
            <li>
              {{ (project.amountOfFollowers == 1 ?
              'project.home.followers.singular' :
              'project.home.followers.plural') | translate:{'followers':
              project.amountOfFollowers} }}
            </li>
          </ul>
        </div>
        <div class="project__about">
          <a
            *ngIf="info == 'show'"
            (click)="showInfo()"
            class="button button-info"
          >
            <ion-icon size="large" name="information-circle"></ion-icon>
          </a>
        </div>
      </div>
      <ul class="project__header__action"></ul>
    </div>

    <div class="project__content">
      <app-update-list *ngIf="updates" [updates]="updates" [project]="project">
      </app-update-list>
    </div>
  </ng-container>
</ion-content>

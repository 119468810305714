import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ProjectEmbedPage } from './pages/project/project-embed/project-embed.page';
import { AboutAppPage } from './pages/settings/about-app/about-app.page';
import { ProfilePage } from './pages/settings/profile/profile.page';
import { AllowLoginGuard } from './guards/allow-login.guard';
import { PermissionsPage } from './pages/settings/permissions/permissions.page';
import { PreferencesPage } from './pages/settings/preferences/preferences.page';
import { LoginPage } from './pages/login/login.page';
import { AddressListPage } from './pages/address/list.page';
import { AddressCreatePage } from './pages/address/create.page';
import { WhitelabelGuard } from './guards/white-label.guard';
import { ProjectResolver } from './services/project-resolver.service';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.routes').then((m) => m.HOME_ROUTES),
  },
  {
    path: 'projects/:slug',
    loadChildren: () =>
      import('./pages/project/project.routes').then((m) => m.PROJECT_ROUTES),
    canActivate: [WhitelabelGuard],
    resolve: {
      project: ProjectResolver,
    },
  },
  {
    path: 'organizations/:slug',
    loadComponent: () =>
      import('./pages/organization/organization.page').then(
        (m) => m.OrganizationPage
      ),
  },
  {
    path: 'organizations/:slug/update/:id',
    loadComponent: () =>
      import(
        './pages/organization/organization-update/organization-update.page'
      ).then((m) => m.OrganizationUpdatePage),
  },
  {
    path: 'notifications',
    loadComponent: () =>
      import('./pages/notifications/notifications.page').then(
        (m) => m.NotificationsPage
      ),
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [AllowLoginGuard],
  },
  {
    path: 'about-app',
    component: AboutAppPage,
  },
  {
    path: 'messages/:id',
    loadComponent: () =>
      import('./pages/message/message.page').then((m) => m.MessagePage),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./pages/onboarding/onboarding.routes').then(
        (m) => m.ONBOARDING_ROUTES
      ),
  },
  {
    path: 'settings/profile',
    component: ProfilePage,
    canActivate: [AllowLoginGuard],
  },
  {
    path: 'settings/permissions',
    component: PermissionsPage,
  },
  {
    path: 'settings/preferences',
    component: PreferencesPage,
  },
  {
    path: 'settings/address/list',
    component: AddressListPage,
  },
  {
    path: 'settings/address/create',
    component: AddressCreatePage,
  },
  {
    path: 'settings/address/:id/edit',
    component: AddressCreatePage,
  },
  {
    path: 'device/loading',
    loadComponent: () =>
      import('./pages/device-user-data/device-user-data.page').then(
        (m) => m.DeviceUserDataPage
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'deeplink/wrapper',
    loadComponent: () =>
      import('./pages/deeplink-wrapper/deeplink-wrapper.page').then(
        (m) => m.DeeplinkWrapperPage
      ),
  },
  {
    path: 'embed/:slug',
    component: ProjectEmbedPage,
    resolve: {
      project: ProjectResolver,
    },
  },
  {
    path: 'v2/:tab/projects/:slug/:project-tab/:id',
    redirectTo: '/projects/:slug/:project-tab/:id',
    pathMatch: 'prefix',
  },
  {
    path: 'v2/:tab/projects/:slug',
    redirectTo: '/projects/:slug',
    pathMatch: 'prefix',
  },
  {
    path: ':tab/projects/:slug',
    redirectTo: '/projects/:slug',
    pathMatch: 'prefix',
  },
  {
    path: 'v2',
    redirectTo: '/feed',
    pathMatch: 'prefix',
  },
  {
    path: '404',
    loadComponent: () =>
      import('./pages/not-found/not-found.page').then((m) => m.NotFoundPage),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

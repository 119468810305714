<app-event
  *ngFor="let event of currentEvents"
  [event]="event"
  [language]="language"
></app-event>

<app-event
  *ngFor="let event of upcomingEvents; let i = index"
  [event]="event"
  [language]="language"
  [hidden]="(!update?.expanded && !expanded) && i >= 2"
></app-event>

<app-event
  *ngFor="let event of pastEvents"
  [event]="event"
  [language]="language"
  [hidden]="!update?.expanded && !expanded"
></app-event>

<ng-container *ngIf="isSticky">
  <div class="sticky-pin">
    <span inlineSVG="/assets/icons/sticky.svg"></span>
  </div>

  <ng-container *ngIf="!expanded && upcomingEvents.length > 2">
    <button class="sticky-read-more" (click)="expand.next()">
      <span inlineSVG="/assets/icons/arrow-down-lg.svg"></span>
    </button>
    <div class="sticky-stacked">
      <div></div>
      <div></div>
    </div>
  </ng-container>
</ng-container>

<button
  class="read-more"
  *ngIf="
    !isSticky &&
    !this.update?.expanded &&
    canShowMore()
  "
  (click)="expand.next()"
>
  {{ 'card.update.events.more' | translate }}
</button>

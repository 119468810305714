import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { LocationService } from './services/location.service';
import { CurrentWhiteLabelApplication } from './utils/current-white-label-application';
import { NetworkService } from './services/network.service';
import { FileService } from './services/file.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StatusBarService } from './services/status-bar.service';
import * as moment from 'moment';
import { BackButtonService } from './services/back-button.service';
import { PwaService } from './services/pwa.service';
import { KeyboardService } from './services/keyboard.service';
import { WhiteLabelApplicationService } from './services/white-label-application.service';
import { TranslationService } from './services/translation.service';
import { SettingsService } from './services/settings.service';
import { DOCUMENT, NgIf } from '@angular/common';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DeepLinkService } from './services/deep-link.service';
import { PushService } from './services/push.service';
import { AnalyticsService } from './services/analytics.service';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Device } from '@capacitor/device';
import { AnalyticsType } from './enums/analytics-type';
import { environment } from 'src/environments/environment';
import { LoaderComponent } from './components/loader/loader.component';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { StorageService } from './services/storage.service';
import { GlobalErrorHandler } from './utils/gloabl-error-handler';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [NgIf, LoaderComponent, IonApp, IonRouterOutlet],
})
export class AppComponent implements OnDestroy {
  ready = false;
  checked = false;

  constructor(
    private title: Title,
    private translate: TranslateService,
    private apiService: ApiService,
    private currentApp: CurrentWhiteLabelApplication,
    private networkService: NetworkService,
    private fileService: FileService,
    private router: Router,
    private locationService: LocationService,
    private statusBarService: StatusBarService,
    private backButtonService: BackButtonService,
    private pwaService: PwaService,
    private whiteLabelApplicationService: WhiteLabelApplicationService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private keyboardService: KeyboardService,
    private translationService: TranslationService,
    private settingsService: SettingsService,
    private pushService: PushService,
    private deeplinkService: DeepLinkService,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.initializeApp();
    this.checkScreenHeight();
    this.apiService.userLoggedOut.subscribe(() => {
      SplashScreen.show();
      this.ready = false;
      this.initializeApp();
    });
  }

  async initializeApp() {
    this.addLinkListener();

    if (!this.pwaService.isPwa()) {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    }

    await this.whiteLabelApplicationService.check();

    this.ready = true;
    this.cd.detectChanges();

    this.whiteLabelApplicationService.initialize();
    this.title.setTitle(this.currentApp.getName());

    this.setupLocalization();

    await this.apiService.init();

    if (!this.pwaService.isPwa()) {
      this.analyticsService.logEvent(AnalyticsType.APP_STARTUP, {});
    }
    const language = await Device.getLanguageCode();
    this.analyticsService.logEvent(AnalyticsType.DEVICE_LANGUAGE, {
      language: language.value,
    });

    this.pushService.setupFirebaseListeners();

    this.fileService.removeScheduledFiles();

    this.networkService.initialize();
    this.locationService.initialize();
    this.deeplinkService.checkDeepLinks();

    this.translationService.setTranslationTitles();
    this.setupDevice();

    this.backButtonService.attach();

    (await this.settingsService.osPushEnabled())
      ? this.pushService.enablePush()
      : this.pushService.disablePush();
    this.analyticsService.initAnalytics();

    this.storageService.remove(GlobalErrorHandler.RELOAD_COUNT_KEY);
    this.storageService.remove(GlobalErrorHandler.LAST_RELOAD_TIME_KEY);
  }

  setupDevice() {
    this.statusBarService.whiteTop();
    setTimeout(() => SplashScreen.hide(), 1300);

    this.keyboardService.hideAccessoryBar();
  }

  setupLocalization() {
    this.translate.setDefaultLang(TranslationService.DEFAULT_LANG);
    let language = this.translationService.getAppLanguage();
    moment.locale(language);
    this.translate.use(language);
    this.document.documentElement.lang = language;
  }

  ngOnDestroy() {
    this.backButtonService.detach();
  }

  private addLinkListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        if (
          event.url.startsWith(environment.linkPrefix + '://') ||
          event.url.startsWith(DeepLinkService.PUSH_LINK_PREFIX + '://')
        ) {
          this.router.navigate(['deeplink', 'wrapper'], {
            queryParams: { link: event.url },
          });
        }
      });
    });
  }

  lastReportedHeight = 0;

  @HostListener('window:resize')
  checkScreenHeight(i = 0) {
    const height = window.innerHeight;

    if (height !== this.lastReportedHeight || i > 1000) {
      document.body.style.setProperty('--screenHeight', `${height}px`);
      this.lastReportedHeight = height;
    } else {
      setTimeout(() => this.checkScreenHeight(i++), 10);
    }
  }
}

<ion-content class="app">
  <div class="app__header" *ngIf="isSettings">
    <span class="back-button">
      <ion-back-button defaultHref="settings" mode="md"></ion-back-button>
    </span>
    <span>{{ 'page.settings.addresses' | translate }}</span>
  </div>
  <div class="app__content address">
    <div class="address__content list">
      <div
        class="address__content__top"
        *ngIf="!isSettings"
        [class.padding]="!isSettings"
      >
        <h2>{{ 'address.list.title' | translate }}</h2>
        <p>{{ 'address.list.desc' | translate }}</p>
      </div>

      <div class="address__content__locations">
        <div>
          <a (click)="clear()"
            >{{ 'address.list.locations.clear' | translate }}</a
          >
        </div>
        <div *ngFor="let address of addresses; let index = index" class="item" [routerLink]="['/settings', 'address', address.id, 'edit']">
          <div *ngIf="addresses.length > 1" class="count">{{index + 1}}</div>
          <span [inlineSVG]="'assets/icons/address-pin.svg'"></span>
          <div class="item__content">
            <p>{{ address.formattedAddress }}</p>
            <p>
              {{ 'address.list.size' | translate: {diameter: (address.radius /
              1000) * 2} }}
            </p>
          </div>
          <button (click)="delete(address, index, $event)">
            <span [inlineSVG]="'assets/icons/trash.svg'"></span>
          </button>
        </div>
        <div class="item new" (click)="add()">
          <span [inlineSVG]="'assets/icons/plus-alt.svg'"></span>
          <p>{{ 'address.list.locations.new' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="address__footer" *ngIf="!isSettings">
      <button class="address__footer__button" (click)="next()">
        {{ 'address.step.next' | translate }}
      </button>
    </div>
  </div>
</ion-content>

import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  public isPwa() {
    return !Capacitor.isNativePlatform();
  }

  public isDesktop() {
    return this.isPwa();
  }
}

import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { WhiteLabelApplicationService } from '../services/white-label-application.service';
import { environment } from '../../environments/environment';
import { Thumbnail } from '../interfaces/thumbnail';
import { WhiteLabelType } from '../enums/white-label-type';

@Injectable({ providedIn: 'root' })
export class CurrentWhiteLabelApplication {
  readonly mainApps: Array<String> = [
    WhiteLabelType.DEBOUWAPP,
    WhiteLabelType.DEBOUWAPPTEST,
    WhiteLabelType.SITEPODIUM,
    WhiteLabelType.WHITELABELTEST,
  ];

  constructor(
    private whiteLabelApplicationService: WhiteLabelApplicationService
  ) {}

  public isProjectApp(): boolean {
    return !!this.whiteLabelApplicationService.currentWhiteLabelApp?.project;
  }

  public isMainApp(): boolean {
    return this.mainApps.includes(
      this.whiteLabelApplicationService.currentWhiteLabelApp.name
    );
  }

  public getCustomer(): string {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.customer;
  }

  public getProjectAppSlug(): string {
    return this.getProjectAppData().slug;
  }

  public isLoginEnabled(): boolean {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.loginEnabled;
  }

  public getProjectAppData(): Project {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.project;
  }

  public getCompanyText() {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.companyText;
  }

  public getApiKey(): string {
    if (!this.whiteLabelApplicationService.currentWhiteLabelApp) {
      return environment.whiteLabelApplicationKey;
    }

    return this.whiteLabelApplicationService.currentWhiteLabelApp.apiKey;
  }

  public getPwaDomain(): string {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.pwaDomain;
  }

  public getLanguage(): string {
    if (!this.whiteLabelApplicationService.currentWhiteLabelApp) {
      return navigator.language.split('-')[0];
    }

    return this.whiteLabelApplicationService.currentWhiteLabelApp.language;
  }

  public getName(): string {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.name;
  }

  public getDistanceType(): string {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.lengthUnits;
  }

  public getBundleIdentifer(): string {
    return this.whiteLabelApplicationService.currentWhiteLabelApp
      .bundleIdentifier;
  }

  public getIOSAppStoreId(): string {
    return this.whiteLabelApplicationService.currentWhiteLabelApp.iOSAppStoreId;
  }

  public getIconThumbnails(): Thumbnail {
    return this.whiteLabelApplicationService.currentWhiteLabelApp
      .appIconThumbnails;
  }
}

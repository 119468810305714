import { EventEmitter, Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { SmartReportingTheme } from '../interfaces/smart-reporting-theme';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { SmartReportingNotice } from '../interfaces/smart-reporting-notice';
import { SmartReportingNoticeResponse } from '../interfaces/smart-reporting-notice-response';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SmartReportingService {
  public requestRedraw = new EventEmitter<SmartReportingNotice>();
  public requestRedraw$ = this.requestRedraw.asObservable();
  public noticeSubmitted = new Subject<number>();
  public noticeSubmitted$ = this.noticeSubmitted.asObservable();
  private noticeSubscribed = new Subject<number>();
  public noticeSubscribed$ = this.noticeSubscribed.asObservable();
  public lastViewDataUpdated$ = new EventEmitter();
  public replied$ = new EventEmitter();

  constructor(private apiService: ApiService) {}

  public themes(project: Project) {
    return this.apiService
      .identifiedGet(
        `/api/v2/smart-reporting-themes?customer=${project.customer.id}`
      )
      .pipe(map((r) => r['hydra:member']))
      .toPromise() as Promise<SmartReportingTheme[]>;
  }

  public createNotice(notice: SmartReportingNotice) {
    return this.apiService
      .identifiedPost('/api/v2/smart-reporting-notices', notice)
      .toPromise();
  }

  public list(project: Project): Promise<SmartReportingNotice[]> {
    return this.apiService
      .identifiedGet(
        `/api/v2/projects/${project.slug}/smart-reporting-notices?pagination=false`
      )
      .pipe(map((r) => r['hydra:member']))
      .toPromise() as Promise<SmartReportingNotice[]>;
  }

  public listSubscribed(project: Project): Promise<SmartReportingNotice[]> {
    return this.apiService
      .identifiedGet(
        `/api/v2/projects/${project.slug}/smart-reporting-notices/subscribed?pagination=false`
      )
      .pipe(map((r) => r['hydra:member']))
      .toPromise() as Promise<SmartReportingNotice[]>;
  }

  public subscribe(notice: SmartReportingNotice) {
    this.noticeSubscribed.next(notice.id);
    return this.apiService
      .identifiedPost(`/api/v2/smart-reporting-notices/subscribe`, {
        notice: notice['@id'],
      })
      .toPromise();
  }

  public unsubscribe(notice: SmartReportingNotice) {
    return this.apiService
      .identifiedPost(`/api/v2/smart-reporting-notices/unsubscribe`, {
        notice: notice['@id'],
      })
      .toPromise();
  }

  public postResponse(
    response: SmartReportingNoticeResponse
  ): Promise<SmartReportingNoticeResponse> {
    return this.apiService
      .identifiedPost(`/api/v2/smart-reporting-notice-responses`, response)
      .toPromise() as Promise<SmartReportingNoticeResponse>;
  }

  public provideFeedback(
    notice: SmartReportingNotice,
    data: any
  ): Promise<SmartReportingNotice> {
    return this.apiService
      .identifiedPut(
        `/api/v2/smart-reporting-notices/${notice.id}/provide-feedback`,
        data
      )
      .toPromise() as Promise<SmartReportingNotice>;
  }

  public details(id: number): Promise<SmartReportingNotice> {
    return this.apiService
      .identifiedGet(`/api/v2/smart-reporting-notices/${id}`)
      .toPromise() as Promise<SmartReportingNotice>;
  }

  public async updateLastViewData(project: Project) {
    try {
      await this.apiService
        .identifiedPut(
          `/api/v3/projects/${project.slug}/smart-reporting/view-data`,
          null
        )
        .toPromise();
      this.lastViewDataUpdated$.emit();
    } catch (error) {
      // ignore
    }
  }
}

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { PwaService } from './pwa.service';
import { StatusBar, Style } from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  constructor(private platform: Platform, private pwaService: PwaService) {}

  public async whiteTop() {
    if (this.pwaService.isPwa()) {
      return;
    }

    await StatusBar.setStyle({ style: Style.Light });

    if (this.platform.is('android')) {
      await StatusBar.setBackgroundColor({ color: '#ffffff' });
    }
  }

  public async blackTop() {
    if (this.pwaService.isPwa()) {
      return;
    }

    await StatusBar.setStyle({ style: Style.Dark });

    if (this.platform.is('android')) {
      await StatusBar.setBackgroundColor({ color: '#000000' });
    }
  }
}

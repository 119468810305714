import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiInit {
  private initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private block: boolean = false;

  public watch(callback: () => any): Promise<any> {
    return new Promise((resolve, reject) =>
      this.initialized.subscribe(async (value) => {
        if (value && !this.block) {
          resolve(await callback());
        }
      })
    );
  }

  public waitForInit() {
    return this.initialized.asObservable().pipe(take(1)).toPromise();
  }

  public setInitialized(value: boolean) {
    this.initialized.next(value);
  }

  public blockApi() {
    this.block = true;
  }

  public unblockApi() {
    this.block = false;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { LoaderComponent } from '../loader/loader.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-translation-button',
  templateUrl: 'translation-button.component.html',
  standalone: true,
  imports: [NgIf, InlineSVGModule, LoaderComponent],
})
export class AppTranslationButtonComponent implements OnInit {
  @Output() translate = new EventEmitter();
  loading: boolean;
  translated: boolean;
  titles: any;

  constructor(private translationService: TranslationService) {}

  async ngOnInit() {
    this.translationService.translateSubject.subscribe(
      (titles) => (this.titles = titles)
    );
  }

  doTranslate(event) {
    this.translate.emit();
    event.stopPropagation();
  }
}

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActionSheetController } from '@ionic/angular/standalone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DistanceType } from 'src/app/enums/distance-type';
import { SettingsService } from 'src/app/services/settings.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import {
  IonContent,
  IonBackButton,
  IonToggle,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.page.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InlineSVGModule,
    TranslateModule,
    IonContent,
    IonBackButton,
    IonToggle,
  ],
})
export class PreferencesPage implements OnInit {
  preferencesForm: FormGroup;
  DistanceType = DistanceType;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private actionSheetCtrl: ActionSheetController,
    private translateService: TranslateService
  ) {
    this.createPreferencesForm();
  }

  async ngOnInit() {
    let suggestionsEnabled = await this.settingsService.getSuggestionsEnabled();
    if (suggestionsEnabled === null) suggestionsEnabled = true;
    let distanceType = await this.settingsService.getDistanceType();

    this.preferencesForm.patchValue(
      {
        suggestionsEnabled: suggestionsEnabled,
        distanceType: distanceType,
      },
      { emitEvent: false }
    );
  }

  async createPreferencesForm() {
    this.preferencesForm = this.fb.group({
      suggestionsEnabled: [],
      distanceType: [],
    });

    this.preferencesForm
      .get('suggestionsEnabled')
      .valueChanges.subscribe((value) => {
        this.settingsService.setSuggestionsEnabled(value).then(() => {
          this.settingsService.settingsChanged$.next(true);
        });
      });

    this.preferencesForm.get('distanceType').valueChanges.subscribe((value) => {
      this.settingsService.saveDistanceType(value).then(() => {
        this.settingsService.settingsChanged$.next(true);
      });
    });
  }

  async showDistanceTypes() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant(
        'settings.preferences.distance-unit.title'
      ),
      buttons: [
        {
          text: this.translateService.instant(
            'settings.preferences.distance-unit.km.full'
          ),
          cssClass:
            this.preferencesForm.get('distanceType').value == DistanceType.KM
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm.get('distanceType').setValue(DistanceType.KM);
          },
        },
        {
          text: this.translateService.instant(
            'settings.preferences.distance-unit.mi.full'
          ),
          cssClass:
            this.preferencesForm.get('distanceType').value == DistanceType.MILES
              ? 'activeBtn'
              : 'settingsBtn',
          handler: () => {
            this.preferencesForm
              .get('distanceType')
              .setValue(DistanceType.MILES);
          },
        },
        {
          text: this.translateService.instant('settings.cancel'),
          cssClass: 'cancelBtn',
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }
}

import { ApiService } from './api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { Poll } from '../interfaces/poll';
import { PollChoice } from '../interfaces/poll-choice';

@Injectable({
  providedIn: 'root',
})
export class PollService {
  public pollVoted = new EventEmitter<Poll>();

  constructor(private apiService: ApiService) {}

  public votePoll(poll: Poll, choice: PollChoice): Promise<any> {
    const data = {
      poll: poll['@id'],
      choice: choice['@id'],
    };

    this.pollVoted.next(poll);

    return this.apiService
      .identifiedPost('/api/v2/polls/vote', data)
      .toPromise();
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public static readonly RELOAD_COUNT_KEY = 'reloadCount';
  public static readonly LAST_RELOAD_TIME_KEY = 'reloadCount';

  constructor(private storageService: StorageService) {}

  async handleError(error: any): Promise<void> {
    //This indicates the app was updated
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      const reloadCount =
        (await this.storageService.get(GlobalErrorHandler.RELOAD_COUNT_KEY)) ??
        0;

      const lastReloadTime = await this.storageService.get(
        GlobalErrorHandler.LAST_RELOAD_TIME_KEY
      );

      const now = Date.now();

      // Check if the reload limit has been exceeded or if reloads are too frequent
      if (reloadCount < 3 || now - lastReloadTime > 60000) {
        // Allow up to 3 reloads or if it's been over 1 minute
        this.storageService.set(
          GlobalErrorHandler.RELOAD_COUNT_KEY,
          reloadCount + 1
        );
        this.storageService.set(
          GlobalErrorHandler.LAST_RELOAD_TIME_KEY,
          now.toString()
        );
        window.location.reload();
      }
    }

    console.error(error);
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular/standalone';
import { CardType } from 'src/app/enums/card-type';
import { DocumentCategory } from 'src/app/interfaces/document-category';
import { Project } from 'src/app/interfaces/project';
import { ProjectDocument } from 'src/app/interfaces/project-document';
import { MapboxService } from 'src/app/services/mapbox.service';
import { ProjectDocumentService } from 'src/app/services/project-document.service';
import { WidgetService } from 'src/app/services/widget.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextDirective } from '../../../../directives/translate-text.directive';
import { CardComponent } from '../../../../components/cards/card/card.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor } from '@angular/common';
import { MapComponent } from 'src/app/components/map/map.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AnalyticsType } from 'src/app/enums/analytics-type';

@Component({
  selector: 'app-project-about-us',
  templateUrl: './project-about-us.component.html',
  standalone: true,
  imports: [
    NgIf,
    InlineSVGModule,
    CardComponent,
    TranslateTextDirective,
    NgFor,
    MapComponent,
    TranslateModule,
  ],
})
export class ProjectAboutUsComponent implements OnInit, AfterViewInit {
  @Input() project: Project;
  @Input() isModal: boolean = false;
  documentCategories: DocumentCategory[];
  map: mapboxgl.Map;
  @ViewChild('mapContainer', { read: ElementRef })
  public mapContainer: ElementRef;
  mapShouldLoad: boolean = false;
  CardType = CardType;

  static readonly extToMimeType = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf',
    gif: 'image/gif',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    png: 'image/png',
  };

  constructor(
    private documentService: ProjectDocumentService,
    private mapboxService: MapboxService,
    private widgetService: WidgetService,
    private modalCtrl: ModalController,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.loadDocuments();
  }

  ngAfterViewInit() {
    this.initMapLoader();
  }

  private initMapLoader() {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          this.mapShouldLoad = true;
          observer.unobserve(this.mapContainer.nativeElement);
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );
    observer.observe(this.mapContainer.nativeElement);
  }

  async loadDocuments() {
    this.documentCategories = await this.documentService.getCategories(
      this.project
    );
    this.documentCategories.forEach((c) =>
      c.documents
        .filter((d) => !d.mimeType)
        .forEach((d) => (d.mimeType = this.determineMimeType(d)))
    );
  }

  public mapLoaded(map: mapboxgl.Map) {
    this.map = map;
    if (
      this.project.locationShapeFileData != null &&
      this.project.locationShapeFileData !== ''
    ) {
      const shapefile = JSON.parse(this.project.locationShapeFileData);

      this.mapboxService.addShapeFile(this.map, this.project.slug, shapefile);
      this.mapboxService.fitMapToShapeFile(this.map, shapefile, 20);
    } else {
      this.mapboxService.drawMarker(this.map, [
        this.project.locationLat,
        this.project.locationLong,
      ]); // draw marker when no location available
    }
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  openDocument(document: ProjectDocument) {
    if (this.widgetService.getIsEmbedded()) return;

    const url = this.isYoutube(document)
      ? 'https://youtu.be/' + document.videoId
      : document.filePathThumbnails.full;

    this.analyticsService.logEvent(
      AnalyticsType.OPEN_ATTACHMENT,
      { url: url },
      this.project
    );

    Browser.open({ url: url });
  }

  isPdf(document: ProjectDocument) {
    return document.mimeType.match('.*pdf');
  }

  isImage(document: ProjectDocument) {
    return document.mimeType.match('image.*');
  }

  isVideo(document: ProjectDocument) {
    if (document.mimeType.match('.*youtube')) return false;
    return document.mimeType.match('video.*');
  }

  isYoutube(document: ProjectDocument) {
    return document.mimeType.match('.*youtube');
  }

  determineMimeType(document: ProjectDocument): string {
    if (document.videoId) return 'video/youtube';

    const extension = document.document.split('.')[1];
    if (extension in ProjectAboutUsComponent.extToMimeType) {
      return ProjectAboutUsComponent.extToMimeType[extension];
    }
    return null;
  }
}

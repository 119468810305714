import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Feed } from '../interfaces/feed';
import { Hydra } from 'src/app/interfaces/hydra';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeedService {
  constructor(private apiService: ApiService) {}

  public getFeed(urlParams?: HttpParams): Observable<Hydra<Feed>> {
    const params = urlParams ? urlParams : new HttpParams();

    const observable = this.apiService
      .identifiedGet('/api/v4/feed', params)
      .pipe(
        map((response) => {
          const responseData = response;
          const obj: Hydra<Feed> = {
            member: responseData['hydra:member'] as Feed[],
            totalItems: responseData['hydra:totalItems'],
          };
          return obj;
        })
      );

    return observable;
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Survey } from '../interfaces/survey';
import { SurveyQuestionAnswer } from '../interfaces/survey-question-answer';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SurveyService {
  public surveySubject = new Subject<Survey>();
  public surveySubmitted = new EventEmitter<number>();

  constructor(private apiService: ApiService) {}

  public getSurveysByProject(slug: string): Promise<Survey[]> {
    return this.apiService
      .identifiedGet('/api/v2/projects/' + slug + '/surveys')
      .toPromise();
  }

  public getSurvey(id: number): Promise<Survey> {
    return this.apiService.identifiedGet('/api/v2/surveys/' + id).toPromise();
  }

  public saveAnswers(
    surveyId: number,
    answers: SurveyQuestionAnswer[]
  ): Promise<SurveyQuestionAnswer[]> {
    this.surveySubmitted.next(surveyId);
    return this.apiService
      .identifiedPost(
        '/api/v2/surveys/' + surveyId + '/questions/answers/batch',
        answers
      )
      .toPromise();
  }

  public getAnswersByDevice(surveyId: number): Promise<SurveyQuestionAnswer[]> {
    return this.apiService
      .identifiedGet(
        '/api/v2/surveys/' + surveyId + '/questions/device/answers'
      )
      .toPromise();
  }
}

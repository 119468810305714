<div
  class="event"
  [ngClass]="event.timeline"
  [class.standalone]="standalone"
  (click)="handleClick(event)"
>
  <span
    [appTranslateText]="event.title"
    [html]="event.title"
    [language]="language"
  >{{ event.title }}</span>
  <div class="event__content">
    <p>
      <ng-container *ngIf="event.timeline == EventTimeline.UPCOMING">
        {{ 'card.update.events.starts' | translate }}
      </ng-container>
      <ng-container *ngIf="event.timeline != EventTimeline.UPCOMING">
        {{ 'card.update.events.started' | translate }}
      </ng-container>
      {{ event.startDate | dateType: 'EVENT' }}
    </p>
    <p *ngIf="event.endDate">
      <ng-container *ngIf="event.timeline == EventTimeline.PAST">
        {{ 'card.update.events.ended' | translate }}
      </ng-container>
      <ng-container *ngIf="event.timeline != EventTimeline.PAST">
        {{ 'card.update.events.ends' | translate }}
      </ng-container>
      {{ event.endDate | dateType : 'EVENT' }}
    </p>

    <ng-container *ngIf="event.timeline == EventTimeline.CURRENT">
      <p>
        <span *ngIf="timeDiffInHours < 1">
          {{ 'less-than-hour' | translate }}
        </span>
        <span *ngIf="timeDiffInHours >= 1">
          {{ event.endDate | countdown }}
          {{ 'card.update.events.remaining' | translate }}
        </span>
      </p>
      <div class="active" [class.soon]="timeDiffInHours < 3">
        <span *ngIf="timeDiffInHours < 3">
          {{ 'card.update.events.ends_soon' | translate }}
        </span>
        <span *ngIf="timeDiffInHours >= 3">
          {{ 'card.update.events.now-label' | translate }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="event.timeline == EventTimeline.UPCOMING">
      <p>
        <span>
          {{ 'card.update.events.starts-in' | translate }}
          {{ event.startDate | countdown }}
        </span>
      </p>
      <div class="active">
        <span>{{ 'card.update.events.soon-label' | translate }}</span>
      </div>
    </ng-container>
  </div>
</div>
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ApiInit } from '../utils/api-init';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular/standalone';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PushService {
  constructor(
    private apiService: ApiService,
    private apiInit: ApiInit,
    private translateService: TranslateService,
    private toastCtrl: ToastController,
    private router: Router
  ) {}

  public async setupFirebaseListeners(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('registration', (token: Token) => {
        this.apiInit.watch(() => this.sendPushToken(token.value));
      });
    }
  }

  async requestPermission(showTip: boolean = false): Promise<boolean> {
    if (!Capacitor.isNativePlatform() || (await this.isPushEnabled())) {
      return true;
    }

    const permission = await PushNotifications.requestPermissions();
    if (permission.receive === 'granted' && (await this.isOSPushEnabled())) {
      this.enablePush();
      return true;
    }

    if (showTip) {
      this.showTip();
    }
    return false;
  }

  async isOSPushEnabled(): Promise<boolean> {
    let enabled;
    try {
      let permStatus = await LocalNotifications.checkPermissions();
      enabled = permStatus.display === 'granted';
    } catch (error) {
      enabled = false;
    }
    return enabled;
  }

  async isPushEnabled(): Promise<boolean> {
    if (!(await this.isOSPushEnabled())) {
      return false;
    }

    return (await PushNotifications.checkPermissions()).receive === 'granted';
  }

  async enablePush() {
    await PushNotifications.register();
    this.savePushEnabled(true);
  }

  async disablePush() {
    this.savePushEnabled(false);
  }

  async savePushEnabled(enabled: boolean) {
    await this.apiInit.waitForInit();

    try {
      await this.apiService
        .identifiedPut(
          '/api/v2/devices/' +
            (await this.apiService.getServerSecret()) +
            '/settings',
          {
            pushEnabled: enabled,
          }
        )
        .toPromise();
    } catch (error) {
      // ignore
    }
  }

  async sendPushToken(pushId: string): Promise<any> {
    if (pushId == null) {
      return;
    }

    const device = await Device.getInfo();
    const uuid = await Device.getId();

    try {
      const response: Response = await this.apiService
        .identifiedPut(
          '/api/v2/devices/' +
            (await this.apiService.getServerSecret()) +
            '/info',
          {
            pushId,
            deviceUuid: uuid.identifier,
            deviceSoftware: device.platform + ' ' + device.osVersion,
            deviceType: device.manufacturer + ' ' + device.model,
          }
        )
        .toPromise();

      return response;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  async showTip() {
    if (
      Capacitor.isNativePlatform() &&
      (await this.isOSPushEnabled()) === false &&
      !this.router.url.includes('onboarding')
    ) {
      const toast = await this.toastCtrl.create({
        message: this.translateService.instant(
          'settings.permissions.notifications.toast'
        ),
        duration: 2000,
        position: 'bottom',
        buttons: [
          {
            text: this.translateService.instant(
              'settings.permissions.notifications.go'
            ),
            handler: () => {
              this.router.navigate(['settings/permissions']);
            },
          },
        ],
      });

      await toast.present();
    }
  }
}

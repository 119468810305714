import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WidgetService } from 'src/app/services/widget.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-smiley-input',
  templateUrl: 'smiley-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmileyInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgFor, InlineSVGModule],
})
export class SmileyInputComponent implements ControlValueAccessor {
  value: number;
  ratings = [1, 2, 3, 4, 5];

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  @Input() disabled: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private widgetService: WidgetService
  ) {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  choose(number: number) {
    if (this.widgetService.getIsEmbedded()) {
      this.widgetService.openApp();
      return;
    }

    if (this.disabled) {
      return;
    }

    this.value = number;

    this.changeDetectorRef.detectChanges(); // fix for frozen UI after push

    this.propagateChange(number);
    this.propagateTouch(number);
  }
}

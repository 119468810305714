import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Device as DeviceObject } from '../interfaces/device';
import { ApiInit } from '../utils/api-init';
import { StorageService } from './storage.service';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { environment } from '../../environments/environment';
import packageJson from '../../../package.json';
import { Device, DeviceInfo } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ModalController, ToastController } from '@ionic/angular/standalone';
import { UpdateAppPage } from '../pages/update-app/update-app.page';
import { TranslateService } from '@ngx-translate/core';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DeviceToken } from '../interfaces/device-token';

@Injectable({ providedIn: 'root' })
export class ApiService {
  public static readonly AUTH_HEADER = 'Device-Auth'; // Be aware! This header is used in the upload service
  public static readonly ACCESS_TOKEN_KEY = 'bouwapp-access-token';
  public static readonly REFRESH_TOKEN_KEY = 'bouwapp-refresh-token';
  private defaultVersion = packageJson.version; // used for web purposes

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );

  userLoggedOut = new Subject<boolean>();
  userLoggedIn = new Subject<boolean>();

  public currentAccessToken = null;

  private readonly baseURL: string;

  private deviceApi = '/api/v2/devices';
  private serverSecretKey = 'serverSecret';
  private clientSecretKey = 'clientSecret';

  private readonly whiteLabelApplication: string;
  private clientSecret: string;
  private serverSecret: string;

  private headers: HttpHeaders;

  private version: string;

  private deviceInfo: DeviceInfo;

  constructor(
    private http: HttpClient,
    private apiInit: ApiInit,
    private storage: StorageService,
    private translateService: TranslateService,
    private currentApp: CurrentWhiteLabelApplication,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController
  ) {
    this.baseURL = environment.baseURL;
    this.loadToken();
  }

  public async init(): Promise<any> {
    this.clientSecret = await this.getClientSecret();

    await this.loadDeviceInfo();
    if (Capacitor.isNativePlatform())
      this.version = (await App.getInfo()).version;

    if (this.version === '' || typeof this.version === 'undefined') {
      this.version = this.defaultVersion;
    }

    try {
      this.serverSecret = await this.getServerSecret();
      this.setDeviceHttpHeaders();

      this.apiInit.setInitialized(true);

      this.apiInit.unblockApi();

      return this.serverSecret;
    } catch (error) {
      console.log('Error while fetching secret', error);
    }
  }

  public getVersion() {
    return this.version;
  }

  private getBaseUrl() {
    return this.baseURL;
  }

  public rawGet(path: string, options: object = null): Observable<any> {
    return this.http.get(path, options);
  }

  public rawPost(
    path: string,
    data: any,
    options: object = null
  ): Observable<any> {
    return this.http.post(path, data, options);
  }

  public get(path: string, options: object = null): Observable<any> {
    return this.http.get(this.getBaseUrl() + path, options);
  }

  public post(path: string, body: any): Observable<any> {
    return this.http.post(this.getBaseUrl() + path, body);
  }

  public identifiedGet(path: string, params?: HttpParams): Observable<any> {
    return this.http.get(this.baseURL + path, this.getRequestOptions(params));
  }

  public identifiedPost(path: string, body: any): Observable<any> {
    return this.http.post(
      this.getBaseUrl() + path,
      body,
      this.getRequestOptions()
    );
  }

  public identifiedPut(path: string, body: any): Observable<any> {
    return this.http.put(
      this.getBaseUrl() + path,
      body,
      this.getRequestOptions()
    );
  }

  public identifiedDelete(path: string): Observable<any> {
    return this.http.delete(this.baseURL + path, this.getRequestOptions());
  }

  public async getServerSecret(): Promise<string> {
    const storedSecret: string = await this.storage.get(this.serverSecretKey);

    if (!storedSecret) {
      return await this.getSecretFromServer();
    } else {
      return storedSecret;
    }
  }

  public getApiCredentials(): string {
    return this.serverSecret + '_' + this.clientSecret;
  }

  private setDeviceHttpHeaders() {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append(
      ApiService.AUTH_HEADER,
      this.getApiCredentials()
    );
    this.headers = this.headers.append('CONTENT_TYPE', 'application/json');
    this.headers = this.headers.append('Accept', 'application/ld+json');
    this.headers = this.headers.append('X-App-Version', this.version);
    this.headers = this.headers.append(
      'X-Locale',
      this.translateService.currentLang
    );
  }

  private getRequestOptions(searchParams?: HttpParams): any {
    const options: any = {};

    options.headers = this.headers;

    if (searchParams) {
      options.params = searchParams;
    }

    return options;
  }

  public async getClientSecret() {
    const storedSecret: string = await this.storage.get(this.clientSecretKey);

    if (!storedSecret) {
      return this.generateClientSecret();
    } else {
      return storedSecret;
    }
  }

  private async generateClientSecret(): Promise<string> {
    const secret: string = <string>uuidv4();
    await this.setClientSecretToStorage(secret);

    return secret;
  }

  public getTruncatedClientSecret(): string {
    return this.clientSecret.split('-', 3).join('-');
  }

  private async getSecretFromServer() {
    await this.loadDeviceInfo();

    const client: DeviceObject = {
      clientSecret: this.clientSecret,
      os: this.getOs(),
      whiteLabelApplication:
        '/api/white-label-applications/' + this.currentApp.getApiKey(),
    };

    const result = await this.post(this.deviceApi, client).toPromise();

    this.serverSecret = result.serverSecret;
    await this.setServerSecretToStorage(this.serverSecret);

    return this.serverSecret;
  }

  private setServerSecretToStorage(id: string) {
    return this.storage.set(this.serverSecretKey, id);
  }

  private setClientSecretToStorage(id: string) {
    return this.storage.set(this.clientSecretKey, id);
  }

  public async loadDeviceInfo(): Promise<void> {
    if (!this.deviceInfo) {
      this.deviceInfo = await Device.getInfo();
    }
  }

  public getOs(): string {
    if (this.deviceInfo.platform === 'ios') {
      return 'iOS';
    } else if (this.deviceInfo.platform === 'android') {
      return 'Android';
    } else {
      return 'web';
    }
  }

  public async checkForUpdate() {
    if (Capacitor.isNativePlatform()) {
      const latestUpdate: string = await this.storage.get('latestUpdate');
      this.identifiedGet('/api/v2/devices/version-update').subscribe(
        (update) => {
          if (
            latestUpdate === JSON.stringify(update) &&
            update.type == 'FLEXIBLE'
          ) {
            //Update rule is the same and flexible, don't show modal
          } else {
            this.storage.set('latestUpdate', JSON.stringify(update));
            this.showUpdateModal(update);
          }
        }
      );
    }
  }

  async showUpdateModal(update) {
    const modal = await this.modalCtrl.create({
      component: UpdateAppPage,
      backdropDismiss: false,
      componentProps: {
        update: update,
      },
    });
    modal.present();
  }

  async loadToken() {
    const token = await this.storage.get(ApiService.ACCESS_TOKEN_KEY);

    if (token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  getSecretData() {
    return this.http.get(`${this.baseURL}/users/me`);
  }

  login(credentials: any): Observable<any> {
    return this.http.post(`${this.baseURL}/auth/token`, credentials).pipe(
      switchMap((tokens: DeviceToken) => {
        this.currentAccessToken = tokens.access_token;
        const storeAccess = this.storage.set(
          ApiService.ACCESS_TOKEN_KEY,
          tokens.access_token
        );
        const storeRefresh = this.storage.set(
          ApiService.REFRESH_TOKEN_KEY,
          tokens.refresh_token
        );
        return from(Promise.all([storeAccess, storeRefresh]));
      }),
      tap((_) => {
        this.userLoggedIn.next(true);
        this.isAuthenticated.next(true);
      })
    );
  }

  logout(): Observable<any> {
    return this.identifiedPost('/magic-link/logout', '{}').pipe(
      catchError(async (error) => {
        this.currentAccessToken = null;
        const clearAll = await this.storage.clear();
        return from(Promise.all([clearAll]));
      }),
      tap((_) => {
        this.presentToast(
          this.translateService.instant('page.logout.success'),
          1500,
          'bottom'
        );
        this.apiInit.blockApi();
        this.userLoggedOut.next(true);
        this.isAuthenticated.next(false);
      })
    );
  }

  getNewAccessToken() {
    const refreshToken = from(this.storage.get(ApiService.REFRESH_TOKEN_KEY));
    return refreshToken.pipe(
      switchMap((token) => {
        if (token) {
          const formData = new FormData();
          formData.append('grant_type', 'refresh_token');
          formData.append('client_id', environment.clientId);
          formData.append('refresh_token', token);

          return this.http.post(`${this.baseURL}/auth/token`, formData);
        } else {
          return of(null);
        }
      })
    );
  }

  getAuthenticated(): Observable<boolean> {
    return this.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        }
        return false;
      })
    );
  }

  storeTokens(tokens: any) {
    this.currentAccessToken = tokens.access_token;
    return from([
      this.storage.set(ApiService.ACCESS_TOKEN_KEY, tokens.access_token),
      this.storage.set(ApiService.REFRESH_TOKEN_KEY, tokens.refresh_token),
    ]);
  }

  async presentToast(message, duration, position) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: position,
    });

    await toast.present();
  }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ImageService {
  public static THUMBNAIL_PARAMETER = 'thumbnail';

  public getVideoImage(youtubeId: string) {
    return 'https://img.youtube.com/vi/' + youtubeId + '/0.jpg';
  }
}

<ion-content class="app" [forceOverscroll]="false">
  <app-media-slider
    [attachments]="attachments"
    [startAtSlide]="startAtSlide"
    [inModal]="true"
    (nextSlide)="setCurrentSlide($event)"
  ></app-media-slider>
</ion-content>

<button
  *ngIf="canShare"
  inlineSVG="/assets/icons/card/share.svg"
  class="share"
  (click)="share()"
></button>
<button
  inlineSVG="/assets/icons/close.svg"
  class="dismiss"
  (click)="dismiss()"
></button>

<span
  *ngIf="attachments[currentSlide].title"
  class="media-slider__description"
  >{{ attachments[currentSlide].title }}</span
>

<span *ngIf="attachments.length > 1" class="media-slider__pagination">
  {{ currentSlide + 1 }} / {{ attachments.length }}
</span>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Project } from 'src/app/interfaces/project';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextDirective } from '../../../../directives/translate-text.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-card-contact',
  templateUrl: './card-contact.component.html',
  standalone: true,
  imports: [NgIf, InlineSVGModule, TranslateTextDirective, TranslateModule],
})
export class CardContactComponent implements OnInit {
  @Input() project: Project;
  @Input() withLink: boolean = false;
  @Output() openLink = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  open() {
    this.openLink.next();
  }
}

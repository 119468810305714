import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '../interfaces/project';
import { LiveStream } from '../interfaces/live-stream.interface';

@Injectable({ providedIn: 'root' })
export class LiveStreamService {
  constructor(private translateService: TranslateService) {}

  public async showStream(project: Project) {
    // const streams = project.liveStreams;
    //
    // if (streams.length === 1) {
    //     this.view(streams[0]);
    // } else {
    //     const options: ActionSheetOptions = {
    //         title: this.translateService.instant('live-stream.info'),
    //         buttonLabels: streams.map(stream => stream.name),
    //         addCancelButtonWithLabel: this.translateService.instant('live-stream.cancel'),
    //         androidTheme: this.actionSheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT = 5,
    //         destructiveButtonLast: true
    //     };
    //
    //     const number = await this.actionSheet.show(options);
    //     if (number <= streams.length) {
    //         const index = number - 1;
    //
    //         this.view(streams[index]);
    //     }
    // }
  }

  private view(stream: LiveStream) {
    // let options: StreamingVideoOptions = {
    //     successCallback: () => { console.log('Video played') },
    //     errorCallback: (e) => { console.error('Error streaming', e) },
    //     shouldAutoClose: true,
    //     controls: false,
    // };
    //
    // this.streamingMedia.playVideo(stream.url, options);
  }
}

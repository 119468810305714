import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Announcement } from '../interfaces/announcement';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(private apiService: ApiService) {}

  public getAnnouncement(id: number): Promise<Announcement> {
    return this.apiService
      .identifiedGet('/api/v2/announcements/' + id)
      .pipe(
        map((response: Announcement) =>
          AnnouncementService.mapAnnouncement(response)
        )
      )
      .toPromise();
  }

  private static mapAnnouncement(object: any): Announcement {
    object.startDate = moment(object.startDate).toDate();
    object.endDate = object.endDate ? moment(object.endDate).toDate() : null;

    return object;
  }
}

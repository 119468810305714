<ion-content class="app">
  <div class="app__header hasDescription">
    <span class="back-button">
      <ion-back-button mode="md" defaultHref="settings"></ion-back-button>
    </span>
    <span>{{ 'page.settings.preferences' | translate }}</span>
  </div>
  <div class="app__description">
    {{ 'page.settings.preferences.desc' | translate }}
  </div>
  <div class="app__content preferences" [formGroup]="preferencesForm">
    <div class="preferences__box">
      <div class="preferences__box__content">
        <h2>{{ 'settings.preferences.suggestions.title' | translate }}</h2>
        <p>{{ 'settings.preferences.suggestions.desc' | translate }}</p>
      </div>
      <div class="preferences__box__toggle">
        <ion-toggle formControlName="suggestionsEnabled"></ion-toggle>
      </div>
    </div>
    <div class="preferences__box" (click)="showDistanceTypes()">
      <div class="preferences__box__content">
        <h2>{{ 'settings.preferences.distance-unit.title' | translate }}</h2>
        <p>{{ 'settings.preferences.distance-unit.desc' | translate }}</p>
      </div>
      <div class="preferences__box__setting">
        <span
          *ngIf="preferencesForm.get('distanceType').value == DistanceType.KM"
        >
          {{ 'settings.preferences.distance-unit.km' | translate }}</span
        >
        <span
          *ngIf="preferencesForm.get('distanceType').value == DistanceType.MILES"
        >
          {{ 'settings.preferences.distance-unit.mi' | translate }}</span
        >
      </div>
      <div
        class="preferences__box__icon"
        [inlineSVG]="'/assets/icons/arrow-right.svg'"
      ></div>
    </div>
  </div>
</ion-content>

import * as moment from 'moment';
import { Event } from 'src/app/interfaces/event';
import { EventTimeline } from '../enums/event-timeline';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CountdownHelper {
  public static getDateTimeline(event: Event): EventTimeline {
    const currDate = moment();
    if (event.endDate && currDate.isBetween(event.startDate, event.endDate)) {
      return EventTimeline.CURRENT;
    } else if (currDate.isBefore(event.startDate)) {
      return EventTimeline.UPCOMING;
    } else if (currDate.isAfter(event.endDate || event.startDate)) {
      return EventTimeline.PAST;
    } else {
      return null;
    }
  }
}

import { StorageService } from './storage.service';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { PwaService } from './pwa.service';

@Injectable({ providedIn: 'root' })
export class LocationService {
  public static DEFAULT_TIMEOUT = 4000;
  public static FIXED_LOCATION_KEY = 'fixedLocation';
  public static HAS_SHOWN_FIXED_LOCATION_KEY = 'hasShownFixedLocation';

  public lastPosition: GeolocationPosition = null;
  private fixedLocation: GeolocationPosition | null; // if this location is set, it will ALWAYS override the current position

  constructor(
    private storageService: StorageService,
    private pwaService: PwaService
  ) {}

  public initialize() {
    Geolocation.watchPosition({}, (position, err) => {
      if (position) {
        this.processPosition(position);
      }
    });
  }

  /**
   * Get current location with a timeout, and if no cached position is available, force to refresh
   * @param forceGps
   * @param timeout
   * @returns {Promise<GeolocationPosition>}
   */
  public getCurrentPosition(
    forceGps = false,
    timeout = LocationService.DEFAULT_TIMEOUT
  ): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      if (this.lastPosition) {
        resolve(this.lastPosition);
        return;
      }

      this.loadLocation().then(async (fixedLocation) => {
        if (fixedLocation != null) {
          resolve(fixedLocation);
          console.warn('Note, fixed location passed.');
        }

        const permissionResult = await this.hasPermission();
        if (!permissionResult) {
          resolve(null);
          return;
        }

        Geolocation.getCurrentPosition({
          enableHighAccuracy: forceGps || this.pwaService.isPwa(),
          timeout,
        })
          .then((value) => this.processPosition(value, resolve))
          .catch((error) => this.processError(error, resolve, reject));

        setTimeout(() => {
          // timeout only occurs when Android devices can't obtain the location
          if (!forceGps) {
            // try with gps (fails on some Android devices)
            this.getCurrentPosition(true, timeout)
              .then((value) => resolve(value))
              .catch((error) => reject(error));
          } else {
            resolve(null);
          }
        }, timeout);
      });
    });
  }

  public async setFixedLocation(coords: GeolocationPosition) {
    await this.storageService.set(LocationService.FIXED_LOCATION_KEY, coords);

    this.fixedLocation = coords;
  }

  public async loadLocation(): Promise<GeolocationPosition> {
    const value = await this.storageService.get(
      LocationService.FIXED_LOCATION_KEY
    );

    if (value != null) {
      this.fixedLocation = value;
    } else {
      this.fixedLocation = null;
    }

    return this.fixedLocation;
  }

  public async hasShownFixedLocation(): Promise<boolean> {
    return await this.storageService.get(
      LocationService.HAS_SHOWN_FIXED_LOCATION_KEY
    );
  }

  public async setShownFixedLocation(value: boolean): Promise<void> {
    await this.storageService.set(
      LocationService.HAS_SHOWN_FIXED_LOCATION_KEY,
      value
    );
  }

  private processPosition(value: GeolocationPosition, resolve = null) {
    this.lastPosition = value;

    if (resolve) {
      resolve(value);
    }
  }

  private processError(error, resolve, reject) {
    if (this.lastPosition) {
      resolve(this.lastPosition); // we have a last position
    } else {
      reject(error);
    }
  }

  private async hasPermission() {
    try {
      return await Geolocation.checkPermissions();
    } catch (error) {
      return { state: 'granted' }; // Some browsers don't support permissions yet
    }
  }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { StorageService } from './storage.service';
import { CacheItem } from '../interfaces/cache-item';

@Injectable({ providedIn: 'root' })
export class CacheService {
  constructor(private storage: StorageService) {}

  /**
   * Get a given cache item
   * @param url
   * @param expiryTime in seconds
   * @returns {Promise<void>}
   */
  public async get(url: string, expiryTime = 3600): Promise<CacheItem> {
    const stored: CacheItem = <CacheItem>(
      await this.storage.get(this.getKey(url))
    );

    if (stored == null) {
      return null;
    } else {
      stored.expired = this.isExpired(stored, expiryTime);

      return stored;
    }
  }

  /**
   * Cache an entry
   * @param url
   * @param data
   */
  public async store(url: string, data: any): Promise<void> {
    const item: CacheItem = {
      value: data,
      date: new Date(),
    };

    this.storage.set(this.getKey(url), item);
  }

  /**
   * Check if a cache item has expired
   * @param cacheItem
   * @param expiryTime in seconds
   * @returns {boolean}
   */
  private isExpired(cacheItem: CacheItem, expiryTime: number) {
    return moment().subtract(expiryTime, 'seconds').isAfter(cacheItem.date);
  }

  /**
   * Returns a cache key for a given url
   * @param url
   * @returns {string}
   */
  private getKey(url: string) {
    return 'cached_' + url;
  }
}

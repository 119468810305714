import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({ providedIn: 'root' })
export class PdfService {
  private static readonly VIEWER_URL = 'https://docs.google.com/viewer?url=';

  constructor() {}

  public openInBrowser(url: string) {
    Browser.open({ url: PdfService.VIEWER_URL + url });
  }
}

export class Base64Helper {
  public static formatBase64JpegImage(data) {
    return 'data:image/jpeg;base64,' + data;
  }
  public static convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
}

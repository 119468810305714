import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';

@Directive({
  selector: 'img[appImgFallback]',
  standalone: true,
})
export class ImgFallBackDirective {
  hasTried: boolean = false;

  constructor(
    private eRef: ElementRef,
    private app: CurrentWhiteLabelApplication
  ) {
    this.eRef.nativeElement.setAttribute('style', 'display: none');
  }

  @HostListener('error')
  loadFallbackOnError() {
    if (!this.hasTried) {
      const element: HTMLImageElement = <HTMLImageElement>(
        this.eRef.nativeElement
      );
      if (this.app.getIconThumbnails()) {
        element.src = this.app.getIconThumbnails().small;
        this.hasTried = true;
      } else {
        element.src = this.eRef.nativeElement.parentNode.removeChild(
          this.eRef.nativeElement
        );
      }
    }
  }

  @HostListener('load')
  showOnLoad() {
    this.eRef.nativeElement.setAttribute('style', 'display: initial');
  }
}

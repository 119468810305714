import { Component, effect, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular/standalone';
import { Project } from '../../../interfaces/project';
import { ProjectService } from '../../../services/project.service';
import { ApiInit } from '../../../utils/api-init';
import { Update } from 'src/app/interfaces/update';
import { UpdateService } from 'src/app/services/update.service';
import { HttpParams } from '@angular/common/http';
import { WidgetService } from 'src/app/services/widget.service';
import { ProjectAboutUsComponent } from '../tabs/project-about-us/project-about-us.component';
import { TranslateModule } from '@ngx-translate/core';
import { UpdateListComponent } from '../../../components/update-list/update-list.component';
import { ImgFallBackDirective } from '../../../directives/img-fallback.directive';
import { NgIf, DatePipe } from '@angular/common';
import { LoaderComponent } from '../../../components/loader/loader.component';
import { addIcons } from 'ionicons';
import { informationCircle } from 'ionicons/icons';
import { IonContent, IonIcon } from '@ionic/angular/standalone';

@Component({
  selector: 'app-project-embed',
  templateUrl: './project-embed.page.html',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    ImgFallBackDirective,
    UpdateListComponent,
    DatePipe,
    TranslateModule,
    IonContent,
    IonIcon,
  ],
})
export class ProjectEmbedPage implements OnInit {
  project: Project;
  updates: Update[];
  header: string;
  theme: string;
  timeline: string;
  info: string;
  loading: boolean = true;
  showBackdrop: boolean = true;

  constructor(
    private projectService: ProjectService,
    private updateService: UpdateService,
    private route: ActivatedRoute,
    private widgetService: WidgetService,
    private modalCtrl: ModalController
  ) {
    addIcons({ informationCircle });

    effect(async () => {
      this.project = this.projectService.getProjectData();
      if (this.project) {
        this.widgetService.init(this.project);
        const params = new HttpParams().set('perPage', '21');
        this.updates = (
          await this.updateService.getAllUpdates(this.project, params)
        ).member;
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    this.header = this.route.snapshot.queryParams.header;
    this.theme = this.route.snapshot.queryParams.theme;
    this.timeline = this.route.snapshot.queryParams.timeline;
    this.info = this.route.snapshot.queryParams.info;
  }

  async showInfo() {
    const modal = await this.modalCtrl.create({
      component: ProjectAboutUsComponent,
      componentProps: {
        project: this.project,
        isModal: true,
      },
      cssClass: 'widget',
    });
    return await modal.present();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Event } from 'src/app/interfaces/event';
import { EventTimeline } from 'src/app/enums/event-timeline';
import { Update } from 'src/app/interfaces/update';
import { EventService } from 'src/app/services/event.service';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { EventComponent } from './event.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  standalone: true,
  imports: [NgFor, EventComponent, NgIf, InlineSVGModule, TranslateModule],
})
export class EventsComponent implements OnInit {
  private _events: Event[];
  @Input() update: Update;
  @Output() expand = new EventEmitter();

  @Input()
  set events(events: Event[]) {
    this._events = this.eventService.sortEvents(events);
    this.initEvents();
  }
  get events(): Event[] {
    return this._events;
  }

  @Input() isSticky: boolean = false;
  EventTimeline = EventTimeline;
  currentEvents: Event[] = [];
  upcomingEvents: Event[] = [];
  pastEvents: Event[] = [];
  @Input() expanded: boolean = false;
  @Input() language: string;

  constructor(private eventService: EventService) {}

  public canShowMore(): boolean {
    return this.currentEvents.length > 2 || this.pastEvents.length > 0;
  }

  ngOnInit() {}

  public initEvents() {
    const events = this.eventService.splitEvents(this.events);
    this.currentEvents = this.eventService.sortEvents(
      events.current,
      'endDate',
      'asc'
    );
    this.upcomingEvents = this.eventService.sortEvents(
      events.upcoming,
      'startDate',
      'asc'
    );
    this.pastEvents = this.eventService.sortEvents(
      events.past,
      'startDate',
      'desc'
    );
  }
}

import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { App } from '@capacitor/app';

@Injectable({ providedIn: 'root' })
export class BackButtonService {
  private backSubscription: Subscription;

  constructor(
    private platform: Platform,
    private modalController: ModalController
  ) {}

  attach() {
    if (this.backSubscription) {
      return;
    }

    this.backSubscription = this.platform.backButton.subscribeWithPriority(
      100000,
      async (processNextHandler) => {
        const element = await this.modalController.getTop();
        console.log('Check app exit.');

        const pathname = window.location.pathname;

        if (element) {
          await element.dismiss();
          return false;
        } else if (
          pathname.split('/').length === 2 &&
          pathname !== '/notifications'
        ) {
          App.exitApp();
          return false;
        } else {
          processNextHandler();
          return true;
        }
      }
    );
  }

  detach() {
    if (!this.backSubscription) {
      return;
    }

    this.backSubscription.unsubscribe();
    this.backSubscription = null;
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { Project } from '../interfaces/project';
import { Ticket } from '../interfaces/ticket';
import { Hydra } from '../interfaces/hydra';
import { catchError, map, retry } from 'rxjs/operators';
import { TicketMessage } from '../interfaces/ticket-message';

@Injectable({ providedIn: 'root' })
export class TicketService {
  private static PROJECT_IRI_PREFIX = '/api/projects/';
  private static NAME_STORAGE_KEY = 'ticketName';
  private static PHONE_NUMBER_STORAGE_KEY = 'ticketPhoneNumber';
  private static EMAIL_STORAGE_KEY = 'ticketEmail';
  public lastViewDataUpdated$ = new EventEmitter();
  public replied$ = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private storageService: StorageService
  ) {}

  /**
   * Store a name for further use in ticketing
   */
  public saveName(name: string): Promise<void> {
    return this.storageService.set(TicketService.NAME_STORAGE_KEY, name);
  }

  public savePhoneNumber(value: string): Promise<void> {
    return this.storageService.set(
      TicketService.PHONE_NUMBER_STORAGE_KEY,
      value
    );
  }

  public saveEmail(value: string): Promise<void> {
    return this.storageService.set(TicketService.EMAIL_STORAGE_KEY, value);
  }

  public async getSavedName(): Promise<string> {
    const name = await this.storageService.get(TicketService.NAME_STORAGE_KEY);

    return name == null ? '' : name;
  }

  public async getSavedPhoneNumber(): Promise<string> {
    const value = await this.storageService.get(
      TicketService.PHONE_NUMBER_STORAGE_KEY
    );

    return value == null ? '' : value;
  }

  public async getSavedEmail(): Promise<string> {
    const value = await this.storageService.get(
      TicketService.EMAIL_STORAGE_KEY
    );

    return value == null ? '' : value;
  }

  public getAllTickets(
    params: HttpParams = new HttpParams(),
    project?: Project
  ): Promise<Hydra<Ticket>> {
    let url;

    if (project != null) {
      url = '/api/v2/projects/' + project.slug + '/tickets';
    } else {
      url = '/api/v2/tickets';
    }

    return this.apiService
      .identifiedGet(url, params)
      .toPromise()
      .then((response: Hydra<Ticket>) => {
        let obj = <Hydra<Ticket>>{};

        obj.member = <Ticket[]>(
          response['hydra:member'].map((object) => this.mapTicket(object))
        );

        obj.totalItems = response['hydra:totalItems'];

        return obj;
      });
  }

  public getTicket(id: number): Promise<Ticket> {
    return this.apiService
      .identifiedGet('/api/v2/tickets/' + id)
      .pipe(map((response) => this.mapTicket(response)))
      .toPromise();
  }

  public getMessages(
    ticket: Ticket,
    type: 'all' | 'before' | 'after',
    date: Date = null,
    limit: number = 10,
    sort: string = 'desc'
  ): Promise<Hydra<TicketMessage>> {
    let params: HttpParams = new HttpParams();

    let customParams = ''; // there is a bug with + signs used in HttpParams so that backends will read it as a space, and its urlencoded equivalent will be encoded twice

    params = params.set('order[createdAt]', sort);
    params = params.set('page', String(1));

    switch (type) {
      case 'all':
        params = params.set('perPage', String(limit));
        break;
      case 'before':
        customParams =
          '?createdAt[strictly_before]=' +
          moment(date).format().replace(/\+/g, '%2B');
        params = params.set('perPage', String(limit));
        break;
      case 'after':
        customParams =
          '?createdAt[strictly_after]=' +
          moment(date).format().replace(/\+/g, '%2B');
        break;
    }

    return this.apiService
      .identifiedGet(
        '/api/v2/tickets/' + ticket.id + '/messages' + customParams,
        params
      )
      .pipe(retry(3))
      .toPromise()
      .then((response: Hydra<TicketMessage>) => {
        let obj = <Hydra<TicketMessage>>{};

        obj.member = <TicketMessage[]>response['hydra:member'].map((object) => {
          object.createdAt = moment(object.createdAt).toDate();

          return object;
        });

        obj.totalItems = response['hydra:totalItems'];

        return obj;
      });
  }

  public createTicket(ticket: Ticket): Promise<any> {
    const data = {
      project: TicketService.PROJECT_IRI_PREFIX + ticket.project.slug,
      messages: [
        {
          content: ticket.messages[0].content,
          attachment: ticket.messages[0].attachment,
        },
      ],
      name: ticket.name,
      subject: ticket.subject,
      locationLat: ticket.locationLat,
      locationLong: ticket.locationLong,
    };

    return this.apiService.identifiedPost('/api/v2/tickets', data).toPromise();
  }

  public replyTicket(ticket: Ticket, message: TicketMessage) {
    return this.apiService
      .identifiedPost('/api/v2/ticket-messages', {
        ticket: '/api/tickets/' + ticket.id,
        content: message.content,
        attachment: message.attachment,
      })
      .toPromise();
  }

  public provideFeedback(ticket: Ticket, feedback: number) {
    return this.apiService
      .identifiedPut('/api/v2/tickets/' + ticket.id + '/provide-feedback', {
        feedback,
      })
      .toPromise();
  }

  public async fetchStatus(ticket: Ticket): Promise<Ticket> {
    const statusData = await this.apiService
      .identifiedGet('/api/v2/tickets/' + ticket.id + '/status')
      .toPromise();

    return statusData;
  }

  private mapTicket(object: any): Ticket {
    object.createdAt = moment(object.createdAt).toDate();

    return object;
  }

  public async updateLastViewData(project: Project) {
    try {
      await this.apiService
        .identifiedPut(
          `/api/v3/projects/${project.slug}/conversations/view-data`,
          null
        )
        .toPromise();
      this.lastViewDataUpdated$.emit();
    } catch (error) {
      // ignore
    }
  }
}

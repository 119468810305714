import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { Share } from '@capacitor/share';
import { ModalController } from '@ionic/angular/standalone';
import { Attachment } from 'src/app/interfaces/attachment';
import { AttachmentService } from '../../services/attachment.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import { MediaSliderComponent } from '../media-slider/media-slider.component';
import { IonContent } from '@ionic/angular/standalone';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AnalyticsType } from 'src/app/enums/analytics-type';
import { Project } from 'src/app/interfaces/project';

@Component({
  selector: 'app-media-slider-modal',
  templateUrl: './media-slider-modal.component.html',
  standalone: true,
  imports: [
    NgIf,
    InlineSVGModule,
    IonContent,
    forwardRef(() => MediaSliderComponent),
  ],
})
export class MediaSliderModalComponent {
  @Input() attachments: Attachment[];
  @Input() startAtSlide: number = 0;
  @Input() project: Project;
  @Output() slideChanged: EventEmitter<number> = new EventEmitter();

  currentSlide: number;
  canShare: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private attachmentService: AttachmentService,
    private analyticsService: AnalyticsService
  ) {
    Share.canShare().then((result) => (this.canShare = result.value));
    this.currentSlide = this.startAtSlide;
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  setCurrentSlide(i: number) {
    this.currentSlide = i;
    this.slideChanged.emit(i);
  }

  share() {
    this.attachmentService.shareAttachment(this.attachments[this.currentSlide]);
    this.analyticsService.logEvent(
      AnalyticsType.IMAGE_SHARED,
      {},
      this.project ?? null
    );
  }
}

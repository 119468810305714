<div class="social-buttons">
  <ul>
    <li
      *ngFor="let type of likeTypes; let i = index"
      (click)="toggleLike(type.key)"
      [attr.data-type]="type.key"
      [class]="type.key"
      class="ion-activatable"
      [class.active]="update.likedBy !== 0 && likedType === type.key"
    >
      <ion-ripple-effect class="ripple"></ion-ripple-effect>
      <div
        [inlineSVG]="'/assets/icons/card/actions/' + type.key + '.svg'"
      ></div>
      <span class="count" [class.show]="type.value > 0">
        {{ type.value }}
      </span>
    </li>
  </ul>
</div>

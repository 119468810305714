import { Component } from '@angular/core';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { ContentTextPipe } from '../../../pipes/content-text.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextDirective } from '../../../directives/translate-text.directive';
import { IonContent, IonBackButton } from '@ionic/angular/standalone';

@Component({
  selector: 'app-about-app',
  templateUrl: './about-app.page.html',
  standalone: true,
  imports: [
    TranslateTextDirective,
    TranslateModule,
    ContentTextPipe,
    IonContent,
    IonBackButton,
  ],
})
export class AboutAppPage {
  language: string;
  aboutText: string;

  constructor(private app: CurrentWhiteLabelApplication) {
    this.language = this.app.getLanguage();
    this.aboutText = this.app.getCompanyText() ?? '';
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Event } from '../interfaces/event';
import { Project } from '../interfaces/project';
import { CountdownHelper } from '../utils/countdown-helper';
import { EventTimeline } from '../enums/event-timeline';

type SortOrder = 'asc' | 'desc';
type SortType = 'startDate' | 'endDate';

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(private apiService: ApiService) {}

  public async getAllEvents(
    daysInFuture: number = 7,
    withPastEvents: boolean = false
  ): Promise<Event[]> {
    return await this.apiService
      .identifiedGet(
        '/api/v2/events?days=' + daysInFuture + '&past=' + withPastEvents
      )
      .toPromise();
  }

  public async getEventsForProject(
    project: Project
  ): Promise<Event[]> {
    return await this.apiService
      .identifiedGet(
        '/api/v2/projects/' + project.slug + '/events'
      )
      .toPromise();
  }
  
  public splitEvents(events: Event[]): {
    current: Event[],
    upcoming: Event[],
    past: Event[]
  } {
    events.map((event) => event.timeline = CountdownHelper.getDateTimeline(event));
    return {
      current: events.filter((event) => event.timeline == EventTimeline.CURRENT),
      upcoming: events.filter((event) => event.timeline == EventTimeline.UPCOMING),
      past: events.filter((event) => event.timeline == EventTimeline.PAST)
    };
  }

  public sortEvents(events: Event[], sortType: SortType = 'startDate', sortOrder: SortOrder = 'asc'): Event[] {
    events.map((event) => event.timeline = CountdownHelper.getDateTimeline(event));
    events.sort((a, b) => {
      const dateA = new Date(a[sortType]).getTime();
      const dateB = new Date(b[sortType]).getTime();
      if(sortOrder == 'asc') return dateA - dateB;
      else return dateB - dateA;
    });

    return events;
  }
}

<ion-content class="app">
  <div class="app__header" *ngIf="isSettings">
    <span class="back-button">
      <ion-back-button defaultHref="settings" mode="md"></ion-back-button>
    </span>
    <span>{{ 'page.settings.addresses' | translate }}</span>
  </div>
  <div class="app__content address" [class.with-header]="isSettings">
    <div class="address__header" *ngIf="!isSettings">
      <a (click)="skip()">{{ 'onboarding.step.skip' | translate }}</a>
    </div>

    <div class="address__map" [class.fullscreen]="fullScreen">
      <div
        (touchstart)="hideOverlay()"
        (mousedown)="hideOverlay()"
        *ngIf="showZoomOverlay"
        class="address__map__overlay"
      >
        <img
          [src]="isDesktop ? '/assets/icons/center.svg' : '/assets/icons/pinch.svg'"
        />
        <p>{{ 'address.create.desc' | translate }}</p>
      </div>
      <app-loader [loading]="loading"></app-loader>
      <app-map
        (mapLoaded)="mapLoaded($event)"
        [minZoom]="2"
        [padding]="{bottom: 10}"
      ></app-map>
      <button
        *ngIf="!loading"
        class="button button-fullscreen"
        (click)="toggleFullscreen()"
      >
        <span [inlineSVG]="'assets/icons/fullscreen.svg'"></span>
      </button>
    </div>

    <div class="address__content map" [class.fullscreen]="fullScreen">
      <div class="address__content__top">
        <h2>{{ 'address.create.title' | translate }}</h2>
        <p
          [ngClass]="{ 'hidden': !zoomError && loadingHistoricalUpdates}"
          [innerHTML]="(zoomError ? 'address.create.zoom-error' : 'address.historical-updates.' + historicalUpdateRange) | translate"
        ></p>
        <button
          class="follow"
          [disabled]="zoomError || loading"
          (click)="getAddress()"
        >
          {{ 'address.create.follow' | translate }}
        </button>
      </div>
    </div>
  </div>
</ion-content>

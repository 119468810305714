import { Injectable } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Project } from '../interfaces/project';

@Injectable({ providedIn: 'root' })
export class MapboxService {
  private baseUrl: string =
    'https://api.mapbox.com/geocoding/v5/mapbox.places/';
  private accessToken: string = environment.mapboxToken;

  constructor(private http: HttpClient) {
    mapboxgl.accessToken = this.accessToken;
  }

  isWebGLSupported(): boolean {
    return mapboxgl.supported();
  }

  reverseGeocode(lngLat: mapboxgl.LngLat) {
    return new Promise(async (resolve, reject) => {
      try {
        const url =
          this.baseUrl +
          lngLat.lng +
          ',' +
          lngLat.lat +
          '.json?access_token=' +
          environment.mapboxToken;
        const response = firstValueFrom(this.http.get(url));
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  geocode(address: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const url =
          this.baseUrl +
          address +
          '.json?access_token=' +
          environment.mapboxToken;
        const response = firstValueFrom(this.http.get(url));
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public addShapeFile(map: mapboxgl.Map, id: string, data: any) {
    if (!map) {
      return;
    }
    const primary = '#F5B049';

    if (!map.getSource(id)) {
      map.addSource(id, {
        type: 'geojson',
        data,
      });
    }

    if (!map.getLayer(id + '-' + 'fill')) {
      map.addLayer({
        id: id + '-' + 'fill',
        type: 'fill',
        source: id,
        layout: {},
        paint: {
          'fill-color': primary,
          'fill-outline-color': primary,
          'fill-opacity': 0.5,
        },
      });
      map.addLayer({
        id: id + '-' + 'stroke',
        type: 'line',
        source: id,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': primary,
          'line-width': 2,
        },
      });
    }
  }

  public clearShapeFiles(map: mapboxgl.Map, ids: string[]) {
    if (!map) {
      return;
    }

    ids.forEach((id) => {
      map.removeLayer(id + '-stroke');
      map.removeLayer(id + '-fill');
    });
  }

  public fitBoundForProjects(map: mapboxgl.Map, projects: Project[]) {
    let coords: mapboxgl.LngLatLike[] = projects.map((project) => [
      project.locationLong,
      project.locationLat,
    ]);
    let bounds = coords.reduce((bounds, coord) => {
      return bounds.extend(coord as mapboxgl.LngLat);
    }, new mapboxgl.LngLatBounds(coords[0], coords[0]));
    map.fitBounds(bounds, { padding: 50, animate: false }, { skipLoad: true });
  }

  public fitMapToShapeFile(map: mapboxgl.Map, shapefile: any, deviation = 0) {
    const coordinates = shapefile.features.reduce((base, feature) => {
      return [
        ...base,
        ...(!Array.isArray(feature.geometry.coordinates[0])
          ? [feature.geometry.coordinates]
          : !Array.isArray(feature.geometry.coordinates[0][0])
          ? feature.geometry.coordinates
          : feature.geometry.coordinates.reduce((prev, coordinateSpace) => {
              return [...prev, ...coordinateSpace];
            }, [])),
      ];
    }, []);

    const validCoordinates = coordinates.filter(
      (coord) => !isNaN(coord[0]) && !isNaN(coord[1])
    );

    if (validCoordinates.length === 0) {
      return;
    }

    const bounds = new mapboxgl.LngLatBounds();
    validCoordinates.forEach((coord) => {
      bounds.extend(coord);
    });

    map.fitBounds(bounds, {
      padding: deviation,
    });
  }

  public setLocation(map: mapboxgl.Map, location: number[]): void {
    if (!map) {
      return;
    }

    map.flyTo({
      animate: false,
      center: [location[1], location[0]],
    });
  }

  public drawLocationCircle(
    map: mapboxgl.Map,
    location: number[],
    accuracy?: number
  ) {
    if (!map) {
      return;
    }

    map.addSource('location', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [location[1], location[0]],
            },
          },
        ],
      },
    } as any);

    map.addLayer({
      id: 'outerCircle',
      type: 'circle',
      source: 'location',
      layout: {},
      paint: {
        'circle-radius': {
          stops: [
            [0, 0],
            [
              20,
              this.metersToPixelsAtMaxZoom(
                accuracy ? accuracy : 500,
                location[0]
              ),
            ],
          ],
          base: 2,
        },
        'circle-color': '#4285F4',
        'circle-opacity': 0.3,
      },
    });
    map.addLayer({
      id: 'innerCircle',
      type: 'circle',
      source: 'location',
      layout: {},
      paint: {
        'circle-radius': 6,
        'circle-color': '#4285F4',
        'circle-opacity': 1,
        'circle-stroke-color': '#FFFFFF',
        'circle-stroke-width': 2,
      },
    });
  }

  public drawMarker(
    map: mapboxgl.Map,
    location: number[],
    icon: string = null,
    baseOptions = {},
    iconWidth = 36,
    iconHeight = 47,
    className = ''
  ) {
    const wrapper = document.createElement('div');
    const el = document.createElement('div');
    el.className = `marker ${className}`;
    el.style.backgroundImage =
      'url(' + (icon ? icon : '/assets/icons/pin.svg') + ')';
    el.style.backgroundSize = '100%';
    el.style.width = iconWidth + 'px';
    el.style.height = iconHeight + 'px';
    el.style.transform = `translateZ(1px) translateY(-${
      iconHeight / 2
    }px) translate(var(--tX, 0), var(--tY, 0))`;

    wrapper.append(el);

    return new mapboxgl.Marker(wrapper)
      .setLngLat([location[1], location[0]])
      .addTo(map);
  }

  // from https://stackoverflow.com/a/18883819
  public calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const R = 6371; // km
    const dLat = this.toRad(lat2 - lat1);
    const dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  private toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  private metersToPixelsAtMaxZoom = (meters, latitude) =>
    meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
}

import { Pipe, PipeTransform } from '@angular/core';
import { LinkyPipe } from 'ngx-linky';
import * as striptags from 'striptags';
import getUrls from 'get-urls';
import { AnalyticsService } from '../services/analytics.service';
import { Browser } from '@capacitor/browser';
import { AnalyticsType } from '../enums/analytics-type';

@Pipe({
  name: 'contentText',
  standalone: true,
})
export class ContentTextPipe implements PipeTransform {
  constructor(
    private linky: LinkyPipe,
    private analyticsService: AnalyticsService
  ) {}

  transform(text: string, ...parameters: any[]): any {
    const result = this.linky.transform(
      this.removeDisallowedTags(text),
      parameters.length > 0 ? parameters[0] : []
    );

    this.checkLinkHandler();

    return result;
  }

  /**
   * Escapes a text
   */
  private removeDisallowedTags(text: string) {
    // before, this would error, now just escape them
    text = text.replace(/["']javascript:(.*?)["']?/gi, '');
    return striptags(text, [
      'a',
      'div',
      'em',
      'u',
      'p',
      'b',
      'i',
      'strong',
      'br',
      'ul',
      'ol',
      'li',
    ]);
  }

  private checkLinkHandler() {
    if ((window as any).openLink == null) {
      (window as any).openLink = (event, url) => {
        event.preventDefault();

        const parsed = getUrls(url);
        const parsedUrl = parsed.values().next().value;

        const final = parsedUrl ? parsedUrl : url;

        if (
          !final.startsWith('https://') &&
          !final.startsWith('http://') &&
          !final.startsWith('mailto:') &&
          !final.startsWith('tel:')
        ) {
          return;
        }

        if (final.startsWith('mailto:') || final.startsWith('tel:')) {
          window.location.href = final;
        } else {
          Browser.open({ url: final });
        }

        this.analyticsService.logEvent(AnalyticsType.VIEW_LINK, {
          document_name: final,
          text_link: event.target ? event.target.innerText : '',
        });
      };
    }
  }
}

<div class="card__content project-suggestion__content">
  <span class="project-suggestion__type">
    <span [inlineSVG]="'/assets/icons/suggestion.svg'"></span>
    <span>{{ 'project-suggestion.suggested' | translate }}</span>
  </span>
  <div
    [appTranslateText]="project.descriptionFormatted | truncate : 300"
    (click)="open()"
    [language]="project.language"
    [html]="project.descriptionFormatted | truncate : 300"
  ></div>
</div>
<span class="card__buttons project-suggestion__buttons">
  <button class="button button--primary" (click)="open()">
    {{ 'project-suggestion.button.open' | translate }}
  </button>
  <button class="button button--secondary" (click)="dismissProject()">
    {{ 'project-suggestion.button.dismiss' | translate }}
  </button>
</span>

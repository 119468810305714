import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { DeviceUser } from 'src/app/interfaces/device-user';
import { ApiService } from 'src/app/services/api.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf } from '@angular/common';
import { LoaderComponent } from '../../../components/loader/loader.component';
import { IonContent, IonBackButton } from '@ionic/angular/standalone';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  standalone: true,
  imports: [
    LoaderComponent,
    NgIf,
    RouterLink,
    InlineSVGModule,
    TranslateModule,
    IonContent,
    IonBackButton,
  ],
})
export class ProfilePage implements OnInit {
  loggedIn: boolean = false;
  user: DeviceUser;
  loading: boolean = false;
  errors: any;
  private static readonly FORM_URL: string =
    'https://forms.gle/QSpP3mzANP36h5U66';

  constructor(
    private apiService: ApiService,
    private onboardingService: OnboardingService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.apiService.getAuthenticated().subscribe((value) => {
      if (value !== true) {
        this.loading = false;
        return;
      }
      this.loggedIn = true;
      this.apiService.identifiedGet('/api/v2/device/me').subscribe(
        (response: DeviceUser) => {
          this.user = response;
          this.loading = false;
        },
        (error) => {
          this.errors = error;
          console.log(error);
        }
      );
    });
  }

  remove() {
    Browser.open({ url: ProfilePage.FORM_URL });
  }

  async logout() {
    this.loading = true;
    this.apiService
      .logout()
      .toPromise()
      .then(() => {
        this.onboardingService.showOnboarding();
      });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Project } from '../interfaces/project';
import { DocumentCategory } from '../interfaces/document-category';
import { map } from 'rxjs/operators';
import { Hydra } from '../interfaces/hydra';

@Injectable({ providedIn: 'root' })
export class ProjectDocumentService {
  constructor(private apiService: ApiService) {}

  public getCategories(project: Project): Promise<DocumentCategory[]> {
    return this.apiService
      .identifiedGet(
        '/api/v2/projects/' + project.slug + '/document-categories'
      )
      .pipe(
        map((result: Hydra<DocumentCategory>) => result['hydra:member']),
        map((categories: DocumentCategory[]) => {
          categories.forEach((category) => {
            category.documents.forEach((document) => {
              if (!document.filePath) {
                document.filePath = document.document;
              }
            });
          });

          return categories;
        })
      )
      .toPromise();
  }
}

<ion-content class="app">
  <div class="app__header">
    <span class="back-button">
      <ion-back-button defaultHref="/" mode="md"></ion-back-button>
    </span>
    <span>{{ 'page.settings.about-app' | translate }}</span>
  </div>
  <div class="app__content about-app">
    <p
      [language]="language"
      [appTranslateText]="aboutText | contentText"
      [html]="aboutText | contentText"
    ></p>
  </div>
</ion-content>

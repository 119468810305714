import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { ProjectService } from 'src/app/services/project.service';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextDirective } from '../../../../directives/translate-text.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';

@Component({
  selector: 'app-card-project-suggestion',
  templateUrl: './card-project-suggestion.component.html',
  standalone: true,
  imports: [
    InlineSVGModule,
    TranslateTextDirective,
    TranslateModule,
    TruncateModule,
  ],
})
export class CardProjectSuggestionComponent {
  @Input() project: Project;
  @Output() openLink = new EventEmitter<void>();

  constructor(private projectService: ProjectService) {}

  public async dismissProject() {
    try {
      await this.projectService.dismissProjectSuggestion(this.project);
    } catch (error) {}
  }

  open() {
    this.openLink.next();
  }
}

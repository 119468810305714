export class AreaHelper {
  public static pixelsToMeters(pixels: number, center: number[], zoom: number) {
    const metersPerPixel =
      (156543.03392 * Math.cos((center[1] * Math.PI) / 180)) /
      Math.pow(2, zoom);
    let radiusInMeters = pixels * metersPerPixel;
    if (radiusInMeters > 5000) radiusInMeters = 5000;
    return radiusInMeters;
  }

  public static calculateZoomLevel(latitude, radiusInMeters) {
    const earthCircumference = 40075000;
    const latitudeInRadians = latitude * (Math.PI / 180);
    return Math.log2(earthCircumference * Math.cos(latitudeInRadians) / (2 * Math.PI * radiusInMeters)) + .1;
  }

  public static createGeoJSONCircle(
    center: number[],
    radiusInMeters: number,
    points: number = 200
  ) {
    let km = radiusInMeters / 1000;

    const ret = [];
    const distanceX = km / (111.32 * Math.cos((center[1] * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([center[0] + x, center[1] + y]);
    }
    ret.push(ret[0]);

    return ret;
  }

  public static createGeoJSONOverlay(center: number[], radiusInMeters: number) {
    const circleCoords = this.createGeoJSONCircle(center, radiusInMeters);

    const outerCoords = [
      [-180, -90],
      [180, -90],
      [180, 90],
      [-180, 90],
      [-180, -90],
    ];

    return {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'Polygon' as const,
        coordinates: [outerCoords, circleCoords],
      },
    };
  }
}

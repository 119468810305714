import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';

const ONBOARDING_KEY = 'hasShownOnboarding';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private hasShownOnboarding: boolean = false;
  private notification: boolean = false;
  private ready = new BehaviorSubject<boolean>(false);
  public obnoardingFinished$ = new EventEmitter<boolean>();

  constructor(private storageService: StorageService, private router: Router) {}

  checkAndShowOnboarding() {
    this.ready.subscribe((ready) => {
      if (ready) {
        this.storageService.get(ONBOARDING_KEY).then((hasShown) => {
          this.hasShownOnboarding = hasShown;

          if (!this.hasShownOnboarding && !this.notification) {
            this.showOnboarding();
          }

          this.notification = false;
        });
      }
    });
  }

  setReady(value) {
    this.ready.next(value);
  }

  setNotification(value) {
    this.notification = value;
  }

  showOnboarding(): void {
    this.hasShownOnboarding = true;
    this.storageService.set(ONBOARDING_KEY, true);
    this.router.navigateByUrl('/onboarding');
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateType',
  standalone: true,
})
export class DateTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * @param date
   * @param parameters, first is type: can be Q1, Q2, Q3, Q4, MONTH, YEAR, EXACT_DATE or EVENT
   */
  transform(date: string | Date, ...parameters: any[]): any {
    const type = parameters[0];
    switch (type) {
      case 'Q1':
      case 'Q2':
      case 'Q3':
      case 'Q4':
        return type + ' ' + moment(date).format('YY');
      case 'MONTH':
        return moment(date).format('MMM YY');
      case 'YEAR':
        return moment(date).format('YYYY');
      case 'EXACT_DATE':
        return moment(date).format('ll');
      case 'EVENT':
        if (moment(date).isSame(moment(), 'day'))
          return `${this.translateService.instant('today')} ${moment(
            date
          ).format('LT')}`;
        else if (moment(date).isSame(moment().add(1, 'days'), 'day'))
          return `${this.translateService.instant('tomorrow')} ${moment(
            date
          ).format('LT')}`;
        else {
          const lang = this.translateService.getBrowserLang();
          let format = lang == 'en' ? 'ddd, MMM D' : 'ddd D MMM';
          if (!moment(date).isSame(moment(), 'year')) format += ', yyyy';
          format += ' LT';
          return moment(date).format(format);
        }
    }
  }
}

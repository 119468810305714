import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DistanceType } from '../enums/distance-type';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import Distance = google.maps.Distance;
import { Subscription } from 'rxjs';
import { SettingsService } from '../services/settings.service';

@Pipe({
  name: 'distance',
  pure: false,
  standalone: true,
})
export class DistancePipe implements PipeTransform, OnDestroy {
  private userDistanceType: DistanceType = DistanceType.KM;
  private subscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private numberPipe: DecimalPipe
  ) {
    this.initializeUserDistanceType();
  }

  transform(value: number, input: DistanceType = DistanceType.KM): unknown {
    const output = this.userDistanceType;
    const label = this.translateService.instant(`projects.distance.${output}`);

    const converted = this.convertDistance(value, input, output);
    const display = this.numberPipe.transform(converted, '1.0-0');
    const finalDisplay = parseInt(display) < 1 ? '< 1' : display;

    return `${finalDisplay} ${label}`;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private convertDistance(
    value: number,
    inputType: DistanceType,
    outputType: DistanceType
  ) {
    const factor = 0.621371;

    if (inputType === outputType) {
      return value;
    }

    if (inputType === DistanceType.KM) {
      return value * factor;
    }

    return value / factor;
  }

  private async initializeUserDistanceType() {
    this.userDistanceType = await this.settingsService.getDistanceType();

    this.subscription = this.settingsService.settingsChanged$.subscribe(
      async () => {
        this.userDistanceType = await this.settingsService.getDistanceType();
      }
    );
  }
}

<div class="smiley-input">
  <div
    *ngFor="let rating of ratings"
    class="smiley-input__rating"
    [inlineSVG]="'assets/icons/emoji/smiley' + rating + '.svg'"
    tabindex="0"
    [class.smiley-input__rating--inactive]="value !== rating"
    (click)="choose(rating)"
    [attr.aria-label]="rating"
    role="button"
  ></div>
</div>

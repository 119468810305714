import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular/standalone';
import { PwaService } from './pwa.service';
import * as localForage from 'localforage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private isInitialized = false;
  private initPromise: Promise<void> = null;

  constructor(
    private platform: Platform,
    private pwaService: PwaService,
    private storage: Storage
  ) {}

  public async init() {
    if (this.initPromise) {
      return this.initPromise;
    }

    this.initPromise = new Promise(async (resolve) => {
      await this.storage.defineDriver(CordovaSQLiteDriver);
      await this.storage.create();

      if (this.platform.is('android') || this.platform.is('ios')) {
        // we have removed secure storage, so we initialize storage to check for existing values
        try {
          await this.platform.ready();
        } catch (error) {
          // device is not secure, so
        }
      }

      this.isInitialized = true;

      if (this.pwaService.isPwa() && (await localForage.length()) > 0) {
        await this.migrateFromLocalForage();
      }

      resolve();
    });
    return this.initPromise;
  }

  public async set(key: string, value: any) {
    if (!this.isInitialized) {
      await this.init();
    }

    return await this.storage.set(key, value);
  }

  public async get(key: string) {
    if (!this.isInitialized) {
      await this.init();
    }

    return await this.storage.get(key);
  }

  public async remove(key: string) {
    return await this.storage.remove(key);
  }

  public async clear() {
    return await this.storage.clear();
  }

  private async migrateFromLocalForage() {
    await localForage.iterate((value: string, key: string) => {
      this.storage.set(key, value ? JSON.parse(value) : null);
    });

    await localForage.dropInstance();
  }
}

import { Injectable } from '@angular/core';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { Project } from '../interfaces/project';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private isEmbedded: boolean;
  private currentProjectSlug: string;

  constructor(
    private currentApp: CurrentWhiteLabelApplication,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  openApp() {
    let url = `//${this.currentApp.getPwaDomain()}/projects/${
      this.currentProjectSlug
    }`;
    window.open(url, '_blank');
  }

  init(project: Project) {
    this.isEmbedded = true;
    this.currentProjectSlug = project.slug;
  }

  getIsEmbedded() {
    return this.isEmbedded;
  }
}

import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  public translateSubject: BehaviorSubject<{}> = new BehaviorSubject({});

  private static TRANSLATION_STORAGE_KEY = 'translation';
  public static readonly DEFAULT_LANG: string = 'en';

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private storageService: StorageService
  ) {}

  public async getTranslation(
    text: string,
    toLanguage: string,
    fromLanguage?: string
  ): Promise<any> {
    return await this.apiService
      .identifiedPost('/api/v1/translate', {
        text: text,
        toLanguage: toLanguage,
        fromLanguage: fromLanguage,
      })
      .toPromise();
  }

  public async setTranslationTitles() {
    let fromLang = this.getAppLanguage();
    let toLang = this.translateService.getBrowserLang();
    let show = this.translateService.instant('projects.translation.show');
    let original = this.translateService.instant(
      'projects.translation.original'
    );
    let translationObject = JSON.parse(
      await this.storageService.get(TranslationService.TRANSLATION_STORAGE_KEY)
    );
    if (fromLang != toLang) {
      if (toLang != translationObject?.language) {
        //user has changed his language since last time, or there is no language set yet
        await this.getTranslation(show, toLang, fromLang).then((result) => {
          show = result.text;
        });
        await this.getTranslation(original, toLang, fromLang).then((result) => {
          original = result.text;
        });
        this.storageService.set(
          TranslationService.TRANSLATION_STORAGE_KEY,
          JSON.stringify({
            language: toLang,
            show: show,
            original: original,
          })
        );
      } else {
        show = translationObject.show;
        original = translationObject.original;
      }
    }

    this.translateSubject.next({
      show: show,
      original: original,
    });
  }

  getAppLanguage(): string {
    const language = this.translateService.getBrowserLang();
    return ['en', 'nl', 'da', 'de', 'fr'].includes(language)
      ? language
      : TranslationService.DEFAULT_LANG;
  }
}

import { Component, Input } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  standalone: true,
  imports: [IonSpinner],
})
export class LoaderComponent {
  @Input() public loading: boolean;
  @Input() public full: boolean = false;
  @Input() public name: string = 'lines';
  @Input() public color: string = 'primary';
  @Input() public withPadding: boolean = false;
}

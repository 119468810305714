import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private checkVideosSubject = new Subject<boolean>();
  checkVideos$ = this.checkVideosSubject.asObservable();

  protected constructor(private router: Router) {
    this.router.events.subscribe(async (ev) => {
      if (ev instanceof NavigationEnd) {
        this.forceStopAll(); //stop videos on all route changes
      }
    });
  }

  checkVideos() {
    this.checkVideosSubject.next(true);
  }

  forceStopAll() {
    let videos = document.getElementsByTagName('video');
    for (let i = 0; i < videos.length; i++) {
      if (
        videos[i].readyState > 2 && 
        videos[i].currentTime > 0 && 
        !videos[i].ended &&
        !videos[i].paused
      ) { 
        videos[i].pause(); 
      } else {
        if(!videos[i].paused) {
          videos[i].addEventListener('canplay', () => {
            videos[i].pause()
          });
        }
      }
    }
  }

  isElementInViewPort(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}

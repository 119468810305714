import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  public online: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {}

  public initialize() {
    Network.addListener('networkStatusChange', (status) => {
      this.online.next(status.connected);
    });
  }

  public isOnline() {
    return this.online.value;
  }

  public async isWifi(): Promise<boolean> {
    return (await Network.getStatus()).connectionType === 'wifi';
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { Project } from '../interfaces/project';
import { Observable } from 'rxjs';
import { Target } from '../interfaces/target';

@Injectable({ providedIn: 'root' })
export class TargetService {
  constructor(
    private apiService: ApiService,
    private cacheService: CacheService
  ) {}

  public getAllTargets(project: Project): Observable<Target[]> {
    return this.getTargets(`/api/v2/projects/${project.slug}/targets/all`);
  }

  private getTargets(url): Observable<Target[]> {
    return new Observable((observer) => {
      // check if cache available
      this.cacheService.get(url, 0).then((cacheEntry) => {
        if (cacheEntry != null) {
          observer.next(cacheEntry.value);
        }

        if (cacheEntry == null || cacheEntry.expired) {
          this.apiService
            .identifiedGet(url)
            .toPromise()
            .then((result) => {
              const targets = <Target[]>result;

              this.cacheService.store(url, targets);
              observer.next(targets);
            })
            .catch((error) => observer.error(error))
            .finally(() => observer.complete());
        }
      });
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { LocationService } from 'src/app/services/location.service';
import { DistancePipe } from '../../../../pipes/distance.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ImgFallBackDirective } from '../../../../directives/img-fallback.directive';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-card-child-project',
  templateUrl: './card-child-project.component.html',
  standalone: true,
  imports: [
    NgIf,
    ImgFallBackDirective,
    InlineSVGModule,
    TranslateModule,
    DistancePipe,
  ],
})
export class CardChildProjectComponent implements OnInit {
  @Input() project: Project;
  withDistance: boolean;
  @Output() openLink = new EventEmitter<void>();

  constructor(private locationService: LocationService) {}

  async ngOnInit() {
    try {
      const location = await this.locationService.getCurrentPosition();
      this.withDistance = true;
    } catch (error) {
      this.withDistance = false;
    }
  }

  open() {
    this.openLink.next();
  }
}

import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslationService } from '../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationButtonComponent } from '../components/translation-button/translation-button.component';
import { Update } from '../interfaces/update';

@Directive({
  selector: '[appTranslateText]',
  standalone: true,
})
export class TranslateTextDirective {
  private _html: SafeHtml;
  @Output() textTranslated = new EventEmitter<string>();
  @Input() language: string;
  @Input() appTranslateText: string;
  @Input() translateOnInit: boolean;
  @Input() update: Update;
  @HostBinding('innerHTML') @Input() set html(html: SafeHtml) {
    this._html = html;
  }
  @HostBinding('class.loading') loading: boolean = false;
  originalText: string;
  translated: boolean = false;
  container: ElementRef;
  button: ComponentRef<AppTranslationButtonComponent>;

  constructor(
    private element: ElementRef,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private factory: ComponentFactoryResolver,
    private target: ViewContainerRef
  ) {
    this.container = this.element.nativeElement;
  }

  async translate() {
    this.button.instance.loading = true;
    if (this.translated) {
      this.translated = false;
      this.button.instance.translated = this.translated;
      if (this.update) {
        this.update['translatedContent'] = null;
      } else {
        this._html = this.originalText;
      }
      this.textTranslated.emit(this.originalText);
    } else {
      try {
        const translation = await this.translationService.getTranslation(
          this.appTranslateText,
          this.translateService.getBrowserLang(),
          this.language
        );
        this.translated = true;
        this.originalText = this.appTranslateText;
        if (this.update) {
          this.update['translatedContent'] = translation.text;
        } else {
          this._html = translation.text;
        }
        this.textTranslated.emit(translation.text);
        this.button.instance.translated = this.translated;
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      this.button.instance.loading = false;
    }, 200);
  }

  get html() {
    return this._html;
  }

  ngOnInit() {
    if (
      this.language &&
      this.language != this.translateService.getBrowserLang().split('-')[0] &&
      this.appTranslateText
    ) {
      this.button = this.target.createComponent(
        this.factory.resolveComponentFactory(AppTranslationButtonComponent)
      );
      this.button.instance.translate.subscribe(() => this.translate());
    }
  }

  ngAfterViewInit() {
    if (this.translateOnInit && this.appTranslateText) this.translate();
  }
}

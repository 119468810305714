import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { ApiService } from '../services/api.service';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular/standalone';
import { OnboardingService } from '../services/onboarding.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  // Used for queued API calls while refreshing tokens
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;

  constructor(
    private apiService: ApiService,
    private toastCtrl: ToastController,
    private onboardingService: OnboardingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if we need additional token logic or not
    if (this.isInBlockedList(request.url)) {
      return next.handle(request);
    } else {
      return next.handle(this.addToken(request)).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 400:
                return this.handle400Error(err);
              case 401:
                return this.handle401Error(request, next);
              default:
                return throwError(err);
            }
          } else {
            return throwError(err);
          }
        })
      );
    }
  }

  // Filter out URLs where you don't want to add the token!
  private isInBlockedList(url: string): Boolean {
    if (url == `${environment.baseURL}/auth/token`) {
      return true;
    }

    return false;
  }

  private addToken(req: HttpRequest<any>) {
    if (this.apiService.currentAccessToken) {
      const headers = req.headers.set(
        'Authorization',
        `Bearer ${this.apiService.currentAccessToken}`
      );
      return req.clone({ headers });
    }
    return req;
  }

  // We are not just authorized, we couldn't refresh token
  // or something else along the caching went wrong!
  private async handle400Error(err) {
    // Potentially check the exact error reason for the 400
    // then log out the user automatically
    const toast = await this.toastCtrl.create({
      message: 'Logged out due to authentication mismatch',
      duration: 2000,
    });
    toast.present();
    this.apiService.logout();
    return of(null);
  }

  // Indicates our access token is invalid, try to load a new one
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // Check if another call is already using the refresh logic
    if (!this.isRefreshingToken) {
      // Set to null so other requests will wait
      // until we got a new token!
      this.tokenSubject.next(null);
      this.isRefreshingToken = true;
      this.apiService.currentAccessToken = null;
      // First, get a new access token
      return this.apiService.getNewAccessToken().pipe(
        switchMap((token: any) => {
          if (token) {
            return this.apiService.storeTokens(token).pipe(
              switchMap((_) => {
                this.tokenSubject.next(token.access_token);

                return next.handle(this.addToken(request));
              })
            );
          } else {
            return of(null);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error.error === 'invalid_request') {
            this.apiService
              .logout()
              .toPromise()
              .then(() => {
                this.onboardingService.showOnboarding();
              });
          }

          return of(null);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(request));
        })
      );
    }
  }
}

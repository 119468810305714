import { EventEmitter, Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { SmartReportingTheme } from '../interfaces/smart-reporting-theme';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private apiService: ApiService) {}

  public sendMagicLink(data: Object) {
    return this.apiService
      .identifiedPost('/magic-link/login_link', data)
      .toPromise();
  }
}

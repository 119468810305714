import { UpdateAttachment } from '../interfaces/update-attachment';
import { Attachment } from '../interfaces/attachment';

export class Attachments {
  static readonly extToMimeType = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf',
    gif: 'image/gif',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    png: 'image/png',
  };

  public static isPdf(attachment: Attachment) {
    return /\.pdf$/.test(attachment.filePath);
  }

  public static isCustomVideo(attachment: Attachment) {
    return (
      /\.mp4$/i.test(attachment.filePath) || /\.mov$/i.test(attachment.filePath)
    );
  }

  public static isImage(attachment: Attachment) {
    return attachment.mimeType.match('image.*');
  }

  public static isYoutubeVideo(attachment: Attachment) {
    return attachment.videoId != null;
  }

  public static is360(attachment: Attachment) {
    return attachment.is360 === true;
  }

  public static isOther(attachment: Attachment) {
    return attachment.mimeType === 'other';
  }

  public static determineMimeType(attachment: Attachment): string {
    if (attachment.videoId) return 'video/youtube';

    const extension = attachment.filePath.split('.')[1].toLowerCase();
    if (extension in Attachments.extToMimeType) {
      return Attachments.extToMimeType[extension];
    }
    return 'other';
  }
}

import { Injectable } from '@angular/core';
import { GAEvent } from '../interfaces/ga-event';
import { ProjectService } from './project.service';
import { Project } from '../interfaces/project';
import { UpdateAttachment } from '../interfaces/update-attachment';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(
    private projectService: ProjectService,
    private apiService: ApiService
  ) {}

  /**
   * Track a view of a project
   * @param project
   */
  public trackProjectView(project: Project) {
    return this.apiService
      .identifiedPost(`/api/v2/statistics/track/project/${project.slug}`, {})
      .toPromise();
  }

  /**
   * Track a view of an update photo
   * @param project
   * @param attachment
   */
  public async trackUpdatePhotoView(
    project: Project,
    attachment: UpdateAttachment
  ) {
    return this.apiService
      .identifiedPost(`/api/v2/statistics/track/update/${attachment.id}`, {})
      .toPromise();
  }
}

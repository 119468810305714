import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/interfaces/project';
import { Event } from 'src/app/interfaces/event';
import { EventTimeline } from 'src/app/enums/event-timeline';
import { TranslationService } from 'src/app/services/translation.service';
import * as moment from 'moment';
import { EventsComponent } from '../../../events/events.component';

@Component({
  selector: 'app-card-events',
  templateUrl: './card-events.component.html',
  standalone: true,
  imports: [EventsComponent],
})
export class CardEventsComponent implements OnInit {
  @Input() events: Event[];
  @Input() project: Project;
  expanded: boolean = false;

  constructor() {}

  ngOnInit() {}
}

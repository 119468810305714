<div class="card--summary__inner contact">
  <div class="card__head">
    <div class="card__image default">
      <img
        *ngIf="project.chatPlaceholderAvatarThumbnails"
        [src]="project.chatPlaceholderAvatarThumbnails?.small"
        width="40"
        height="40"
        alt="{{ project.chatPlaceholderName }}"
      />
    </div>
    <div class="card__title">
      <span>
        <span>{{ project.chatPlaceholderName }}</span>
      </span>
      {{ project.chatPlaceholderFunction }}
    </div>
  </div>
  <div class="card--summary__body">
    <ng-container *ngIf="project.chatPlaceholderPhoneNumber">
      <a
        [href]="'tel:' + project.chatPlaceholderPhoneNumber"
        class="card--summary__phone"
      >
        <div
          class="inline-svg contact-info__phone-number__icon"
          inlineSVG="assets/icons/phone.svg"
          [attr.aria-label]="'contact-info.phone' | translate"
        ></div>
        {{ project.chatPlaceholderPhoneNumber }}
      </a>
    </ng-container>
  </div>
  <div class="card__content">
    <div
      class="card--summary__description"
      [appTranslateText]="project.chatDescription"
      [html]="project.chatDescription"
      [language]="project.language"
    ></div>
  </div>
  <a *ngIf="withLink" class="card--summary__link" (click)="open()">
    {{ 'project.home.contact.action' | translate : { project: project.name } }}
  </a>
</div>

<div class="card--child" (click)="open()">
  <div class="card--child__image">
    <img
      *ngIf="project.logoThumbnails"
      appImgFallback
      [src]="project.logoThumbnails.small"
      width="48"
      height="48"
      alt="{{ project.name }}"
    />
    <img
      *ngIf="!project.logoThumbnails"
      appImgFallback
      [src]="project.customer.logoThumbnails?.small"
      width="48"
      height="48"
      alt="{{ project.name }}"
    />
  </div>
  <div class="card--child__content">
    <span *ngIf="project.distance && withDistance"
      >{{ project.distance | distance }}
      {{ 'search.project.distance' | translate }}</span
    >
    <h2>{{ project.name }}</h2>
    <span>{{ project.participants }}</span>
    <span>{{
      (project.amountOfFollowers == 1
        ? 'card.followers.singular'
        : 'card.followers.plural'
      ) | translate : { followers: project.amountOfFollowers }
    }}</span>
  </div>
  <div
    class="card--child__icon"
    [inlineSVG]="'/assets/icons/arrow-right.svg'"
  ></div>
</div>

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Feed } from '../interfaces/feed';
import { Hydra } from 'src/app/interfaces/hydra';
import { Subscription } from 'rxjs';
import { Notification } from 'src/app/interfaces/notification';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private apiService: ApiService) {}

  public getNotifications(urlParams: HttpParams): Promise<Hydra<Notification>> {
    let params = urlParams ? urlParams : new HttpParams();
    params = params.set('order[createdAt]', 'desc');

    return this.apiService
      .identifiedGet('/api/v3/notifications', params)
      .toPromise()
      .then((responseData: Hydra<Notification>) => {
        let obj = <Hydra<Notification>>{};

        obj.member = <Notification[]>responseData['hydra:member'];

        obj.totalItems = responseData['hydra:totalItems'];

        return obj;
      });
  }
}
